<template>
  <div class="mt-1 mr-1 mb-5" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddBookEntry"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          @click="onOpenGenerateBrokerStatements('commission_mode')"
          >Effettua Calcoli Provvigionali</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          @click="onOpenGenerateBrokerStatements('broker_statement_mode')"
          >Genera Rendiconti</b-button
        >
      </b-col>
    </b-row>

    <b-collapse id="collapse-1" v-model="showCollapse1">
      <b-form @submit.prevent="onSearch(filterName, true)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="cod_prod"
                label="Produttore"
                :options="salesmen"
                v-model="salesmen_ids"
                :multiple="true"
                :closeOnSelect="false"
                @input="onSalesmen"
              />
              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllBrokers()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllBrokers()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggle"
                variant="light"
                class="mb-2"
                @click="myToggle ? selectAllBrokers() : deselectAllBrokers()"
                >{{
                  myToggle ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="int_prod"
                label="Intestazione documenti prodotti"
                :options="intProd"
                v-model="salesman_id"
              />
              <!-- @input="onSalesman" -->
            </div>
            <div class="col-md-3">
              <base-select
                name="insurers"
                label="Compagnia"
                :options="insurers"
                v-model="filter.byInsurer.id"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="from"
                label="Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="to"
                label="Registrazione Al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-checkbox
                name="arretrati"
                label="Includi"
                v-model="arretrati"
                :chk_val="true"
                :unchk_val="false"
                :isSwitch="true"
                groupLabel="Includi registrazioni escluse dai rendiconti precedenti"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-radio
                name="calculus_type"
                label="Modalità"
                v-model="calculus_type"
                :options="calculus_options"
                stacked
                @select="onCalculusTypeChange"
              />
            </div>
            <div class="col-md-3">
              <!-- NOTA: con :unchk_val="null" => non aggiunge "byExclusion" su queryString -->
              <base-checkbox
                :disabled="!calculus_type"
                name="esclusi"
                label="Escludi"
                v-model="filter.byExclusion"
                :chk_val="true"
                :unchk_val="null"
                :isSwitch="true"
                groupLabel="Escludi dal ricalcolo provvigionale Lisaweb le compagnie e i rami indicati nell'anagrafica Compagnie"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <span class="font-weight-bold">
      Modalità Rendiconto:
      {{ $store.state.auth.settings.attributables.STATMOD }}
    </span>
    <span class="font-weight-bold" v-if="salesman_id">
      :: Intestazione documenti prodotti:
      {{ salesmen.find((s) => s.value == salesman_id).text }} :: Dal
      {{ filter.byCalendar.from }} :: Al {{ filter.byCalendar.to }}
    </span>
    <salesmen-accounting
      :filterOn="{
        byRelations: [
          'byBookTag',
          'byInsurer',
          'byRegistry',
          'byInsuranceAncillary',
          'byVariousAccounting',
        ],
      }"
      :fields="flds"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      @rowSelector="updateOne"
      @cellChange="onCellChange"
      @edit="onEdit"
      @confirm="onConfirm"
      @destroy="onDelete"
      @rowSelector2="onStatementExclude"
      :onlyActions="['edit', 'destroy']"
      includeBottomRow
      noPagination
      includeRowSelector
      hasChecks
    ></salesmen-accounting>
    <!-- :statmod="$store.state.auth.settings.attribute_STATMOD_value" -->

    <!-- <div>
      <select-option-modal
        :opts="[
          { text: 'Si', value: 'Y' },
          { text: 'No', value: 'N' },
        ]"
        title="Stampa percentuali applicate ?"
        @select="onSelectOption"
      />
    </div> -->
    <b-row v-show="$refs[tableRef] && $refs[tableRef].items.length">
      <div
        style="
          width: 15px;
          height: 15px;
          background: white;
          margin-left: 18px;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      ></div>
      <p
        style="
          margin-left: 5px;
          margin-right: 5px;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      >
        Registrazione in intervallo date
      </p>
      <div
        style="
          width: 15px;
          height: 15px;
          background: pink;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      ></div>
      <p
        style="
          margin-left: 5px;
          margin-right: 5px;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      >
        Registrazione arretrata
      </p>
      <div
        style="
          width: 15px;
          height: 15px;
          background: orange;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      ></div>
      <p
        style="
          margin-left: 5px;
          margin-right: 5px;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      >
        Registrazione esclusa dal rendiconto
      </p>
      <div
        style="
          width: 15px;
          height: 15px;
          background: purple;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      ></div>
      <p
        style="
          margin-left: 5px;
          margin-right: 5px;
          padding: 0;
          margin-top: 8px;
          font-size: 12px;
        "
      >
        Movimento vario modificabile
      </p>
    </b-row>

    <b-overlay center :show="isUpd" rounded="sm">
      <div v-show="!isUpd">
        <b-button
          v-show="$refs[tableRef] && $refs[tableRef].items.length"
          type="button"
          variant="lisaweb"
          class="mt-1"
          @click="updateAll"
          :disabled="changedIds.length == 0"
          >Aggiorna Tutti</b-button
        >
        <!-- v-show="
            $refs[tableRef] &&
            $refs[tableRef].items.length &&
          " -->
        <b-button
          v-show="
            $refs[tableRef] &&
            $refs[tableRef].items.length &&
            $refs[tableRef].selectedRows.length &&
            changedIds.length == 0
          "
          type="button"
          variant="lisaweb"
          class="mt-1"
          @click="onReset"
          :disabled="!canShowBrokerStatement"
          >Ricarica</b-button
        >
        <div
          v-show="
            $refs[tableRef] &&
            $refs[tableRef].items.length &&
            $refs[tableRef].selectedRows.length &&
            changedIds.length == 0
          "
        >
          <b-button
            type="button"
            variant="lisaweb"
            class="mt-1"
            @click="drawBrokerStatement"
            :disabled="canShowBrokerStatement"
            >Rendiconto</b-button
          >
          <div v-if="canShowBrokerStatement" class="mt-1 mb-1">
            <export-options
              :exportUrl="exportUrl"
              :repository="repository"
              :resource="resource"
              :tableRef="$refs[tableRef]"
              :filter="filter"
              :options="[
                {
                  code: 'RENPROVUNOFFICIALIZED',
                  label: null,
                  formats: ['csv', 'pdf'],
                  extra: {
                    produttori: smids, // REVIEW: still require this?
                    salesman_id: smid,
                  },
                },
              ]"
            ></export-options>
            <!-- // REVIEW: seems that BE does not require all salesmen ids for this report, except the "principal" one.
              Such salesmen will be recognized by BE based on byAttribute[id] array received, 
              which is generated here by tableRef later on in ExportsMixin -->
            <!-- <button-group-table
              @pdf="report('pdf')"
              @csv="report('csv')"
              :print="false"
              :options="{
                CSV: { enabled: true },
                PDF: { enabled: true },
              }"
            ></button-group-table> -->
            <!-- tabella riepilogo -->
            <b-card
              :header="getDictionary('balance_sheet')"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <table class="summary">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2">
                        {{ getDictionary("totals") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <!-- premi -->
                      <th scope="row">{{ getDictionary("premiums") }}</th>
                      <td>{{ toLocaleCurrency(summary.entrate) }}</td>
                    </tr>
                    <tr>
                      <!-- Di cui sospesi da incassare -->
                      <th scope="row">
                        {{ getDictionary("of_which_deferred_to_collect") }}
                      </th>
                      <td>{{ toLocaleCurrency(summary.sospesi) }}</td>
                    </tr>
                    <tr>
                      <!-- Acconti -->
                      <th scope="row">
                        {{ getDictionary("advance_payments") }}
                      </th>
                      <td>{{ toLocaleCurrency(summary.acconti) }}</td>
                    </tr>
                    <tr>
                      <!-- Provvigioni -->
                      <th scope="row">{{ getDictionary("commissions") }}</th>
                      <td>
                        {{ toLocaleCurrency(summary.provvigioni) }}
                      </td>
                    </tr>
                    <tr>
                      <!-- di cui non soggette a ritenuta d'acconto -->
                      <th scope="row">
                        {{ getDictionary("of_which_not_taxable") }}
                      </th>
                      <td>{{ toLocaleCurrency(summary.di_cui) }}</td>
                    </tr>
                    <tr>
                      <!-- Ritenuta d'acconto (X%) -->
                      <th scope="row">
                        {{ getDictionary("deduction") }} ({{
                          summary.ritenuta_perc
                        }}%)
                      </th>
                      <td>{{ toLocaleCurrency(summary.ritenuta) }}</td>
                    </tr>
                    <tr>
                      <!-- di cui diritti -->
                      <th scope="row">
                        {{ getDictionary("fees") }}
                      </th>
                      <td>{{ toLocaleCurrency(summary.di_cui_diritti) }}</td>
                    </tr>
                    <tr>
                      <!-- Provvigioni da liquidare -->
                      <th scope="row">
                        {{ getDictionary("commissions_to_liquidate") }}
                      </th>
                      <td>
                        {{ toLocaleCurrency(summary.da_liquidare) }}
                      </td>
                    </tr>
                    <tr>
                      <!-- Saldo premi -->
                      <th scope="row">
                        {{ getDictionary("premiums_balance") }}
                      </th>
                      <td>{{ toLocaleCurrency(summary.saldo) }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
            </b-card>
            <!-- Ufficializza Rendiconto -->
            <b-card
              :header="getDictionary('make_official_balance_sheet')"
              header-tag="header"
              class="mt-1"
            >
              <!-- <template #header>
                      <div>
                        <b-button
                          type="button"
                          variant="lisaweb"
                          class="mt-1"
                          @click="onSaveBrokerStatement"
                          >Ufficializza Rendiconto</b-button
                        >
                        Uffcializza
                      </div>
                    </template> -->
              <b-card-text>
                <!-- 2 input  -->
                <b-row class="mt-1">
                  <div class="col-md-2">
                    <!-- Numero fattura -->
                    <base-input
                      :name="getDictionary('invoice_number')"
                      :label="getDictionary('invoice_number')"
                      v-model="form.invoice_number"
                    />
                  </div>
                  <div class="col-md-2">
                    <!-- Periodo -->
                    <base-input
                      :name="getDictionary('period')"
                      :label="getDictionary('period')"
                      v-model="form.invoice_period"
                    />
                  </div>
                  <div class="form-group col-md-2 align-self-end">
                    <b-button
                      type="button"
                      variant="lisaweb"
                      class="mt-1"
                      :disabled="!salesman_id"
                      @click="onSaveBrokerStatement"
                      >Ufficializza Rendiconto</b-button
                    >
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <generate-broker-statements-modal
        :ref="generateBrokerStatementModalRef"
        :title="broker_statement_title"
        :header="broker_statement_header"
        @generate="onGenerate"
      ></generate-broker-statements-modal>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Aggiornamento in corso...</p>
        </div>
      </template>
    </b-overlay>
    <div ref="anchor1"></div>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import GenerateBrokerStatementsModal from "@/components/modals/generateBrokerStatementsModal";
import SalesmenAccounting from "@/components/tables/SalesmenAccounting";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseDatepicker from "@/components/form/BaseDatepicker";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import SelectOptionModal from "@/components/modals/selectOption";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, ConfirmModalMixin, FormMixin],
  data() {
    return {
      exportUrl: null,
      isSaving: false,
      isUpdating: false,
      myToggle: false,
      filter: this.initFilter(),
      exportFormat: null,
      insurers: [],
      salesmen_ids: [], // i produttori della tendina multipla
      salesman_id: null, // il produtore selezionato dalla seconda tendina (popolata con i produttori selezionati dalla prima)
      arretrati: null,
      show_percentages: "N",
      // esclusi: null,
      calculus_type: 1, // TODO diventerà byQualcosa...
      showCollapse1: true,
      // canBrokerStatement: false,
      canShowBrokerStatement: false,
      changedIds: [],
      prevCheckedItemIds: [],
      filterName: "filterSalesmenAccounting",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "SalesmenAccountingTableRef",
      fields: [],
      broker_statement_title: null,
      broker_statement_header: null,
      mode: null,
      generateBrokerStatementModalRef: "generateBrokerStatementModal",
      selectableFields: {
        0: [
          {
            key: "check",
            label: "Sel.",
            sortable: false,
            thClass: "check text-center",
            tdClass: "text-center",
            class: "check",
          },
          {
            key: "deferred",
            label: "Sospeso",
            sortable: false,
          },
          {
            key: "book_date",
            label: "D.Reg",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          {
            key: "effective_date",
            label: "D.Eff",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          // {
          //   key: "title",
          //   label: "Descrizione",
          //   sortable: false,
          // },
          {
            key: "customer",
            label: "Contraente/descrizione",
            sortable: false,
          },
          {
            key: "insurance_policy",
            label: "Polizza",
            sortable: false,
          },
          {
            key: "book_tag",
            label: "C.Doc",
            sortable: false,
          },
          {
            key: "company",
            label: "Com",
            sortable: false,
          },
          {
            key: "broker",
            label: "Prod",
            sortable: false,
          },
          {
            key: "branch_risk",
            label: "Ramo-rischio",
            sortable: false,
          },
          {
            key: "gross",
            label: "Premio",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "net",
            label: "Imp",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "agency_prov_purchase",
            label: "Provv.Acq.Age",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "agency_prov_take",
            label: "Provv.Inc.Age",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "saler_prov_purchase",
            // label: "Provv.Acq.Prod",
            label: "Provv.Calcolata.Acq",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "saler_prov_take",
            // label: "Provv.Inc.Prod",
            label: "Provv.Calcolata.Inc",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "saler_fee_take",
            label: "Dir.Inc.Prod",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "tot_prod",
            label: "Tot.Prod",
            sortable: false,
            // formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "confirmRow",
            label: "Confermato",
            sortable: false,
            thClass: "check",
            tdClass: "text-center",
          },
          // class: "check",
          {
            key: "rowSelector",
            label: "Aggiorna",
            sortable: false,
            thStyle: {
              width: "86px",
            },
          },
          {
            key: "rowSelector2",
            label: "Arretrati",
            sortable: false,
            thStyle: {
              width: "70px",
            },
          },
        ],
        1: [
          {
            key: "id",
            label: "[ID] - Campi per modalità rendicontro Altro DA DEFINIRE",
            sortable: false,
          },
        ],
      },
      form: {
        invoice_number: null,
        invoice_period: null,
      },
      summary: {
        entrate: 0,
        sospesi: 0,
        acconti: 0,
        di_cui_disponibili: 0,
        provvigioni: 0,
        di_cui: 0,
        ritenuta: 0,
        ritenuta_perc: 0.0,
        da_liquidare: 0,
        saldo: 0,
      },
      salesmen: [],
      calculus_options: [
        { value: 0, text: "Utilizza già calcolate" },
        { value: 1, text: "Calcola con Lisaweb" },
      ],
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseRadio,
    BaseCheckbox,
    BaseDatepicker,
    SalesmenAccounting,
    // ButtonGroupTable,
    ExportOptions,
    // SelectOptionModal,
    GenerateBrokerStatementsModal,
  },
  methods: {
    toLocaleCurrency,
    // toCurrency(value) {
    //   if (!value) value = "0.00001"; // trick to allow display "0.00 €"
    //   return toLocaleCurrency(parseFloat(value));
    // },
    selectAllBrokers() {
      this.salesmen_ids = this.getSalesmen().map(
        (cooperator) => cooperator.value
      );
      this.onSalesmen();
    },
    deselectAllBrokers() {
      this.salesmen_ids = [];
      this.salesman_id = null;
    },
    initFilter() {
      let init = {
        byAttribute: {
          is_statement_excluded: "N",
          // is_confirmed: "N", // CHECK: dipende dalla modalità? va inviato sempre?
        },
        // #772: un-fixed
        byBrokerStatement: {
          id: "!",
        },
        byInsurer: {
          id: null,
        },
        // byBroker: {
        //   id: null,
        // },
        byCalendar: {
          from: null,
          to: null,
        },
        // byInsurer: {
        //   exclude_calc_vehicle_branch: null,
        //   exclude_calc_extra_vehicle_branch: null,
        //   exclude_calc_life_branch: null,
        // },
        byExclusion: null, // #667: TODO check the real filter name!!!
        // calculus_type: 0, // TODO diventerà byQualcosa...
        // byCommissionApplied: {
        //   salesman: null
        // },
        // arretrati: 0,
      };
      return init;
    },
    // report(format) {
    //   this.exportFormat = format;
    //   this.$bvModal.show("selectOption");
    // },
    // onSelectOption(value) {
    //   if (value != null) {
    //     this.onExport(this.exportFormat, "RENPROVUNOFFICIALIZED", {
    //       // prospetto: this.getTotalizers(),
    //       produttori: this.salesmen_ids,
    //       salesman_id: this.salesman_id,
    //       show_percentages: value,
    //     });
    //   }
    // },
    onAddBookEntry() {
      // have to open the "salesmen" variant of book_entries create
      this.$router.push({
        name: `book_entries.create`,
        params: { mode: "II" },
      });
    },
    onEdit(item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${item.id}`, item: item, mode: "II" },
      });
    },
    onDelete(id) {
      console.debug("deleting: ", id);
    },
    // onEditByType(id, item) {
    //   // TODO: recuperare il tipo di mov vario da item
    //   // console.log(id, item);
    //   let type = null;
    //   switch (type) {
    //     case 1:
    //       break;

    //     default:
    //       break;
    //   }
    // },
    onSalesmen() {
      // console.log("onSalesmen:", id);
      // se c'è almeno 1 salesmen_ids...
      if (this.salesmen_ids.length) {
        // ...setta come preselezionato il 1° in ordine di selezione nella seconda tendina intProd
        this.salesman_id = this.salesmen_ids[0];
        this.onSalesman(this.salesman_id);
        // ...setta come preselezionato il 1° in ordine di id nella seconda tendina intProd
        // this.salesman_id = Math.min(...this.salesmen_ids);
      } else {
        this.salesman_id = null;
        this.summary.ritenuta_perc = 0.0;
      }
    },
    onSalesman(val) {
      // la seconda tendina
      // recupero il WHT
      let found = this.$store.state.auth.salesmen.find((e) => e.id == val);
      if (found) {
        this.summary.ritenuta_perc = found.attributables.WHT
          ? parseFloat(found.attributables.WHT)
          : 0.0;
      } else {
        this.summary.ritenuta_perc = 0.0;
      }
    },
    onConfirm(item) {
      // devo solo abilitare il bottone aggiorna... senza salvare
      // CHECK quale dei 2 approcci?

      // NOTA: non è memorizzato il valore al "caricamento", ma solo il valore "attuale"
      // 1. se Y aggiunge, se N rimuove
      // if (item.is_confirmed.value == "Y") {
      //   this.addToChangedIds(item.id);
      // } else {
      //   this.removeFromChangedIds(item.id);
      // }

      // 2. aggiungo sempre, sia Y sia N
      this.addToChangedIds(item.id);
    },
    /* onConfirm(item) {
      // salvo immediatamente
      // per la PUT del campo is_confirmed, vanno inviati tutti i campi mandatory
      // 1. chiamare: edit/item.id
      // 2. preparare payload con campi obbligatori oltre a is_confirmed
      console.debug("onConfirm", item);
      let id = item.id;
      this.fetchEditForm("book_entry", id)
        .then(() => {
          this.initMandatoryFields("book_entry");
          // save
          let payload = {};
          payload = { ...this.mandatoryFields[this.repository] };
          this.setMandatoryValues(this.repository, payload);
          // set values for fields with rules: required_without_all
          this.setRequiredWithoutAll(payload, item);
          // payload = { ...payload, ...form };

          payload["is_confirmed"] = item.is_confirmed.value;

          let msg =
            item.is_confirmed.value === "Y" ? "confermato" : "non confermato";

          this.update(this.repository, id, payload)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: ${msg}`,
              });
              // fetch table data?
              // this.table.fetch().then(() => {
              //   this.fetchForm().finally(() => {
              //     this.isLoading = false;
              //   });
              // });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    }, */
    onStatementExclude(item) {
      // per la PUT del campo is_statement_excluded, vanno inviati tutti i campi mandatory
      // 1. chiamare: edit/item.id
      // 2. preparare payload con campi obbligatori oltre a is_statement_excluded
      console.debug("onStatementExclude", item);
      let id = item.id;
      this.fetchEditForm("book_entry", id)
        .then(() => {
          this.initMandatoryFields("book_entry");
          // save
          let payload = {};
          payload = { ...this.mandatoryFields[this.repository] };
          this.setMandatoryValues(this.repository, payload);
          // set values for fields with rules: required_without_all
          this.setRequiredWithoutAll(payload, item);
          payload["is_statement_excluded"] = "Y"; // item.is_statement_excluded.value;

          this.update(this.repository, id, payload)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: arretrato escluso`,
              });
              // fetch table data?
              // this.table.fetch().then(() => {
              //   this.fetchForm().finally(() => {
              //     this.isLoading = false;
              //   });
              // });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onCellChange(id) {
      this.addToChangedIds(id);
      // accendo is_confirmed
      this.setConfirmedRow(id);
    },
    addToChangedIds(id) {
      // add to global list
      if (this.changedIds.indexOf(id) === -1) {
        this.changedIds.push(id);
        // REVIEW: devo impostare is_confirmed per tutti a 'Y'?
        // set is_confirmed = Y
        // this.setConfirmedRow(id);
      }

      // console.debug("this.changedIds: ", this.changedIds);
    },
    removeFromChangedIds(id) {
      // this.changedIds = this.changedIds.filter((e) => {
      //   return e !== id;
      // });
      let index = this.changedIds.indexOf(id);
      if (index !== -1) {
        this.changedIds.splice(index, 1);
      }
    },
    setChangedIds(data) {
      this.changedIds = [];
      data.forEach((item) => {
        // if calculated value is different, then row must be updatable...
        if (
          // item.saler_prov_purchase != item.purchase_commission ||
          // item.saler_prov_take != item.take_commission
          // #1045: take2: fix 6.81, 7.81 arrivano come X.8100000000000005
          this.$refs[this.tableRef].isChangeable(item) &&
          (parseFloat(item.saler_prov_purchase).toFixed(2) !=
            parseFloat(item.purchase_commission).toFixed(2) ||
            parseFloat(item.saler_prov_take).toFixed(2) !=
              parseFloat(item.take_commission).toFixed(2) ||
            parseFloat(item.saler_fee_take).toFixed(2) !=
              parseFloat(item.insurer_fee).toFixed(2))
        ) {
          this.addToChangedIds(item.id);
        }
      });
    },
    setConfirmedRows(data) {
      data.forEach((item) => {
        this.setConfirmedRow(item.id);
      });
    },
    setConfirmedRow(id) {
      // setta confirmed... mah..
      let item = this.$refs[this.tableRef].items.find((e) => e.id == id);
      // console.log("item:", item);
      if (item) {
        item.is_confirmed.value = "Y";
        // console.log("==========================");
      }
    },
    // isInChangedIds(id) {
    //   return this.changedIds.indexOf(id) === -1
    // },
    scrollTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    updateCall(row) {
      const Repo = RepositoryFactory.get(this.repository);
      const id = row.id;
      const body = {
        saler_prov_purchase:
          this.calculus_type == 0
            ? row.saler_prov_purchase
            : row.purchase_commission,
        saler_prov_take:
          this.calculus_type == 0 ? row.saler_prov_take : row.take_commission,
        saler_fee_take: row.insurer_fee, // #1112
        book_date: row.book_date,
        effective_date: row.effective_date,
        sheet_date: row.sheet_date,
        net: row.net,
        gross: row.gross,
        // salesman_id: row.salesman_id,  // ??? row non ha salesman_id, e il v-model qui è this.salesman_id
        insurer_id: row.insurer_id,
        is_confirmed: row.is_confirmed.value,
        broker: {
          [row.brokers[0].id]: {},
        },
        // various_accounting: {
        //   [row.various_accountings[0].id]: {
        //     insurance_risk_id:
        //       row.various_accountings[0].pivot.insurance_risk_id,
        //   },
        // },
      };
      this.setRequiredWithoutAll(body, row);
      // console.debug("updating...", id);
      // console.debug("payload", body);
      return Repo.update(id, body);
      // .then(() => {
      //   if (!updateAll) {
      //     let index = this.changedIds.indexOf(id);
      //     if (index !== -1) {
      //       this.changedIds.splice(index, 1);
      //     }
      //     // console.debug("this.changedIds: ", this.changedIds);
      //   }
      //   showSnackbar({
      //     preset: "success",
      //     text: updateAll
      //       ? `Hai Aggiornato tutte le righe (id: ${id})`
      //       : `Hai aggiornato la riga`,
      //   });
      // })
      // .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     showSnackbar({
      //       preset: "error",
      //       text: `${errMsg}`,
      //     });
      //   })
    },
    updateOne(row) {
      // save only if changes occurred
      let index = this.changedIds.indexOf(row.id);
      if (index !== -1) {
        this.isUpdating = true;
        this.updateCall(row)
          .then(() => {
            // tolgo da changedIds..
            this.changedIds.splice(index, 1);
            this.$showSnackbar({
              preset: "success",
              text: `Hai aggiornato la riga`,
            });

            // se è l'ultimo, ricarico la table, in modo che riesegue le operazioni post-fetch
            // quali ad esempio la colorazione delle celle etc...
            this.isUpdating = false;
            if (!this.changedIds.length) {
              //   // this.$nextTick(() => {
              this.onSearch(this.filterName);
              //   // });
            }
            // // if(!this.changedIds.length) {
            // //   // bottone Rendiconto
            // //   this.canBrokerStatement = true;
            // // }
          })
          .catch((error) => {
            this.isUpdating = false;
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "info",
          text: `La riga non è ancora stata modificata: niente da salvare`,
        });
      }
    },
    /* updateAll() {
      // filtra le righe da aggiornare tenendo solo quelle in this.changedIds
      let promises = [];
      let rows = this.$refs[this.tableRef].items.filter(
        (item) =>
          this.changedIds.includes(item.id) &&
          this.$refs[this.tableRef].isChangeable(item)
      );
      this.isUpdating = true;
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        promises.push(this.updateCall(row));
      }
      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            // text: `Hai aggiornato le righe (ID: [${this.changedIds.join(
            //   ", "
            // )}])`,
            text: "Hai aggiornato tutte le righe",
          });
          this.changedIds = [];
          this.isUpdating = false;
          this.onSearch(this.filterName);
          // bottone Rendiconto
          // this.canBrokerStatement = true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    }, */
    updateAll() {
      // #1112 call bulk_update
      const Repo = RepositoryFactory.get(this.repository);

      // filtra le righe da aggiornare tenendo solo quelle in this.changedIds
      let bulkBody = {};
      let rows = this.$refs[this.tableRef].items.filter(
        (item) =>
          this.changedIds.includes(item.id) &&
          this.$refs[this.tableRef].isChangeable(item)
      );
      this.isUpdating = true;
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        const id = row.id;
        // promises.push(this.updateCall(row));
        const body = {
          saler_prov_purchase:
            this.calculus_type == 0
              ? row.saler_prov_purchase
              : row.purchase_commission,
          saler_prov_take:
            this.calculus_type == 0 ? row.saler_prov_take : row.take_commission,
          // saler_fee_take: row.saler_fee_take, // #1112
          saler_fee_take:
            this.calculus_type == 0 ? row.saler_fee_take : row.insurer_fee, // #1112
          // book_date: row.book_date,
          // effective_date: row.effective_date,
          // sheet_date: row.sheet_date,
          // net: row.net,
          // gross: row.gross,
          // // salesman_id: row.salesman_id, // ??? row non ha salesman_id, e il v-model qui è this.salesman_id
          // insurer_id: row.insurer_id,
          is_confirmed: row.is_confirmed.value,
          // broker: {
          //   [row.brokers[0].id]: {},
          // },
          // various_accounting: {
          //   [row.various_accountings[0].id]: {
          //     insurance_risk_id:
          //       row.various_accountings[0].pivot.insurance_risk_id,
          //   },
          // },
        };
        // this.setRequiredWithoutAll(body, row);
        bulkBody[id] = body;
      }

      Repo.bulk_update(bulkBody)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            // text: `Hai aggiornato le righe (ID: [${this.changedIds.join(
            //   ", "
            // )}])`,
            text: "Hai aggiornato tutte le righe",
          });
          this.changedIds = [];
          this.isUpdating = false;
          this.onSearch(this.filterName);
          // bottone Rendiconto
          // this.canBrokerStatement = true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    setRequiredWithoutAll(body, row) {
      // 1. "insurance_ancillary"
      // 2. "insurer_accounting"
      // 3. "various_accounting"

      // 1. insurance_ancillaries
      if (row.insurance_ancillaries && row.insurance_ancillaries.length) {
        const insurance_ancillary_id = row.insurance_ancillaries[0].id;

        body["insurance_ancillary"] = {
          [insurance_ancillary_id]: {},
        };
      }

      // 2. insurer_accounting
      // TODO: ???

      // 3. various_accounting
      if (row.various_accountings && row.various_accountings.length) {
        const various_accountings_id = row.various_accountings[0].id;

        body["various_accounting"] = {
          [various_accountings_id]: {
            insurance_risk_id:
              row.various_accountings[0].pivot.insurance_risk_id,
          },
        };
      }
    },
    drawBrokerStatement() {
      // se il produttore intProd non è selezionato, il bottone ufficializza è disabilitato
      if (!this.salesman_id) {
        this.$showSnackbar({
          preset: "info",
          text: "Per poter ufficializzare il rendiconto, occorre selezionare un produttore in Intestazione documenti prodotti",
        });
      }
      // 1. mettere in sola lettura il checkbox e i campi input della table
      let table = this.$refs[this.tableRef];
      table.isTableReadonly = true;
      // GET /api/book_entries?byTotals=W&byAttribute[id]=X&byBroker[id]=Y&page=0
      const Repo = RepositoryFactory.get("book_entry");
      let book_entry_ids = this.$refs[this.tableRef].selectedRows.join(",");
      let broker_ids = this.salesmen_ids.join(",");
      let queryString = `byTotals[id]=${this.salesman_id}&byAttribute[id]=${book_entry_ids}&byBroker[id]=${broker_ids}`;

      Repo.index(queryString)
        .then((response) => {
          this.setTotalizers(response.data.data[0]);
          // mostrare bottone "Ufficializza rendiconto"
          this.canShowBrokerStatement = true;
          this.$nextTick(() => this.scrollTo("anchor1"));
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
    },
    getTotalizers() {
      return [
        { [this.getDictionary("premiums")]: this.summary.entrate },
        {
          [this.getDictionary("of_which_deferred_to_collect")]:
            this.summary.sospesi,
        },
        { [this.getDictionary("advance_payments")]: this.summary.acconti },
        { [this.getDictionary("commissions")]: this.summary.provvigioni },
        { [this.getDictionary("of_which_not_taxable")]: this.summary.di_cui },
        {
          [`${this.getDictionary("deduction")} (${
            this.summary.ritenuta_perc
          }%)`]: this.summary.ritenuta,
        },
        { [this.getDictionary("fees")]: this.summary.di_cui_diritti }, // #1112
        {
          [this.getDictionary("commissions_to_liquidate")]:
            this.summary.da_liquidare,
        },
        { [this.getDictionary("premiums_balance")]: this.summary.saldo },
      ];
    },
    setTotalizers(data) {
      this.summary.ritenuta_perc = parseFloat(data.broker_wht);
      this.summary.entrate = parseFloat(data.gross);
      this.summary.sospesi = parseFloat(data.deferred);
      this.summary.acconti = parseFloat(data.deposit);
      this.summary.provvigioni =
        parseFloat(data.purchase) + parseFloat(data.take);
      this.summary.di_cui =
        parseFloat(data.purchase_tax) + parseFloat(data.take_tax);
      this.summary.di_cui_diritti = parseFloat(data.fees);
      this.summary.ritenuta = parseFloat(data.deduction);
      // this.summary.da_liquidare = data.purchase + data.take - data.deduction;
      // this.summary.saldo =
      //   data.gross -
      //   data.deposit -
      //   (data.purchase + data.take) +
      //   data.deduction;
      this.summary.da_liquidare =
        parseFloat(data.purchase) +
        parseFloat(data.take) -
        parseFloat(data.deduction) +
        parseFloat(data.fees);
      this.summary.saldo =
        parseFloat(data.gross) -
        (parseFloat(data.purchase) + parseFloat(data.take)) +
        parseFloat(data.deduction) -
        parseFloat(data.fees);
    },
    sumRitenute(data, keys) {
      let hasChecks = true;
      let tot = 0;
      keys.forEach((key) => {
        let parts = key.split(".");
        tot += data
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati e se ancillaries è vuoto e se various è diveso da 1
            return (!hasChecks || (hasChecks && row.check)) &&
              // !row.insurance_ancillaries.length &&
              // row.various_accountings[0].code != "FC"
              row.is_taxable.value === "N"
              ? +parseFloat(parts.reduce((acc, part) => acc && acc[part], row))
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumPositiveAdvancePayment(data) {
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "AC" &&
              row.gross > 0
              ? parseFloat(row.gross)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumNegativeAdvancePayment(data) {
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "AC" &&
              row.gross < 0
              ? parseFloat(row.gross)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumSospesi(data) {
      // sommare tutti i gross dei dettagli con cassa sospesi (id=3 or code=SS)
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati e se è una cassa sospesi
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "SS"
              ? +parseFloat(row.gross)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumSospesiSummary(data) {
      // sommare tutti i gross_deferred
      // REVIEW: in futuro anche questa cambierà...
      let hasChecks = true;
      let tot = 0;
      tot = data
        .map((row) => {
          // somma solo se in presenza dei check, sono checkati e se esiste gross_deferred
          return (!hasChecks || (hasChecks && row.check)) && row.gross_deferred
            ? +parseFloat(row.gross_deferred)
            : 0;
        })
        .reduce((sum, i) => {
          return sum + i;
        }, 0);
      return tot;
    },
    sumAdvancePaymentSummary(data, field) {
      // sommare tutti i deposit_in
      // REVIEW: in futuro anche questa cambierà...
      let hasChecks = true;
      let tot = 0;
      tot = data
        .map((row) => {
          // somma solo se in presenza dei check, sono checkati e se esiste gross_deferred
          return (!hasChecks || (hasChecks && row.check)) && row[field]
            ? +row[field]
            : 0;
        })
        .reduce((sum, i) => {
          return sum + i;
        }, 0);
      return tot;
    },
    sumDiritti(data, keys) {
      let hasChecks = true;
      let tot = 0;
      keys.forEach((key) => {
        let parts = key.split(".");
        tot += data
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati
            return !hasChecks || (hasChecks && row.check) // &&
              ? // !row.insurance_ancillaries.length &&
                // row.various_accountings[0].code != "FC"
                // row.is_taxable.value === "N"
                +parts.reduce((acc, part) => acc && acc[part], row)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    onSaveBrokerStatement() {
      // payload must contain:
      // salesman_id (presi dal filtro originale)
      // date_from  (presi dal filtro originale)
      // date_to (presi dal filtro originale)
      // numero fattura (al momento text)
      // periodo (al momento text)
      // array di bookentry (presi da selectedRows)
      if (!this.form.invoice_period || !this.form.invoice_number) {
        this.showConfirm({
          yesCallback: () => {
            this.saveBrokerStatement();
          },
          noCallback: null,
          title: "Conferma",
          message:
            "Uno dei campi Numero Fattura/Periodo è vuoto. Procedere comunque?",
          yesLabel: "PROCEDI",
          yesVariant: "outline-lisaweb",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        this.showConfirm({
          yesCallback: () => {
            this.saveBrokerStatement();
          },
          noCallback: null,
          title: "Conferma",
          message: "Procedere e ufficializzare il rendiconto?",
          yesLabel: "PROCEDI",
          yesVariant: "outline-lisaweb",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      }
    },
    /* saveBrokerStatement() {
      this.isSaving = true;
      let payload = {};
      let ids = this.$refs[this.tableRef].selectedRows.join(",");
      let filterString = `byAttribute[id]=${ids}`;
      // payload.salesman = { [this.salesman_id]: {} };
      payload.broker_id = this.salesman_id;
      payload.from = this.filter.byCalendar.from;
      payload.to = this.filter.byCalendar.to;
      payload.invoice_number = this.form.invoice_number;
      payload.invoice_period = this.form.invoice_period;
      // CHECK this out!!!
      // payload.book_entry = { id: this.$refs[this.tableRef].selectedRows };
      // console.debug("payload: ", payload);
      // #740: salvare il summary in totalizers
      let totalizers = this.getTotalizers();
      payload.totalizers = totalizers;
      // amount_due
      payload.amount_due = this.summary.saldo;
      // is_collected = N
      payload.is_collected = "N";

      const RepoBrokerStatement = RepositoryFactory.get("broker_statement");
      const Repo = RepositoryFactory.get(this.repository);
      RepoBrokerStatement.store(payload)
        .then((response) => {
          let id = response.data.data.id;
          payload = {
            broker_statement_id: id,
          };
          Repo.filter_update(payload, filterString)
            .then(() => {
              this.$showSnackbar({
                preset: "info",
                text: "Rendiconto ufficializzato",
              });
              // TODO #849: 2 chiamate per PDF una con percentuali=Y e una con percentuali=N
              this.exportAll(id)
                .then(() => {
                  this.$showSnackbar({
                    preset: "info",
                    text: `Richiesta accettata: report in elaborazione`,
                  });
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: errMsg,
                  });
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            })
            .finally(() => {
              this.isSaving = false;
              this.resetPage();
              this.onClearFilter(this.filterName);
              this.showCollapse1 = true;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    }, */
    saveBrokerStatement() {
      this.isSaving = true;
      let payload = {};
      // let ids = this.$refs[this.tableRef].selectedRows.join(",");
      payload.broker_id = this.salesman_id;
      payload.from = this.filter.byCalendar.from;
      payload.to = this.filter.byCalendar.to;
      payload.invoice_number = this.form.invoice_number;
      payload.invoice_period = this.form.invoice_period;
      // payload.amount_due = this.summary.saldo;
      payload.is_collected = "N";
      payload.book_entry = this.$refs[this.tableRef].selectedRows;

      const RepoBrokerStatement = RepositoryFactory.get("broker_statement");
      RepoBrokerStatement.store(payload)
        .then((response) => {
          let id = response.data.data.id;
          this.$showSnackbar({
            preset: "info",
            text: "Rendiconto ufficializzato",
          });
          // TODO #849: 2 chiamate per PDF una con percentuali=Y e una con percentuali=N
          this.exportAll(id)
            .then(() => {
              this.$showSnackbar({
                preset: "info",
                text: `Richiesta accettata: report in elaborazione`,
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isSaving = false;
          this.resetPage();
          this.onClearFilter(this.filterName);
          this.showCollapse1 = true;
        });
    },
    exportAll(broker_statement_id) {
      // non così: altra modalità senza queryString e con solo broker_statement_id
      // this.onExport(
      //   "pdf",
      //   "RENPROV",
      //   {
      //     // prospetto: this.getTotalizers(),
      //     produttori: this.salesmen_ids,
      //     salesman_id: this.salesman_id,
      //     show_percentages: "Y",
      //   },
      //   true // silent
      // );
      // this.onExport(
      //   "pdf",
      //   "RENPROV",
      //   {
      //     prospetto: this.getTotalizers(),
      //     produttori: this.salesmen_ids,
      //     salesman_id: this.salesman_id,
      //     show_percentages: "N",
      //   },
      //   true // silent
      // );
      // devo inviare broker_statement_id, senza queryString e con questo payload
      let promises = [];
      let report_id = this.$store.state.auth.reports.find(
        (e) => e.code === "RENPROV"
      ).id;
      const Repo = RepositoryFactory.get("book_entry");

      let payload = {
        format: "pdf",
        report_id,
        show_percentages: null,
        broker_statement_id,
      };
      payload.show_percentages = "Y";
      promises.push(Repo.report_rendiconto(null, payload));
      payload.show_percentages = "N";
      promises.push(Repo.report_rendiconto(null, payload));
      payload.format = "csv";
      payload.show_percentages = "Y";
      promises.push(Repo.report_rendiconto(null, payload));
      payload.show_percentages = "N";
      promises.push(Repo.report_rendiconto(null, payload));
      return Promise.all(promises)
        .then(() => {})
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onCalculusTypeChange(checkedOption) {
      console.debug("checkedOption: ", checkedOption);
      // this.$showSnackbar({
      //   preset: "info",
      //   text: "Non implementato",
      //   actionText: "OK",
      // });
      if (!checkedOption) {
        this.filter.byExclusion = null;
      }
      this.changedIds = [];
      // reset items
      this.$refs[this.tableRef].items = [];
      // reset checkAll checkbox
      // this.$refs[this.tableRef].checkAll = false;
      this.resetPage();
    },
    onOpenGenerateBrokerStatements(mode) {
      switch (mode) {
        case "broker_statement_mode":
          this.broker_statement_header = "Genera Rendiconti";
          this.broker_statement_title =
            "Stai per generare i rendiconti dei produttori selezionati";
          this.mode = "broker_statement_mode";
          break;
        case "commission_mode":
          this.broker_statement_header = "Effettua Calcoli Provvigionali";
          this.broker_statement_title =
            "Stai per generare i calcoli provvigionali dei produttori selezionati";
          this.mode = "commission_mode";
          break;
      }
      this.$bvModal.show(this.generateBrokerStatementModalRef);
    },

    onGenerate(form) {
      this.$bvModal.hide(this.generateBrokerStatementModalRef);

      const Repo = RepositoryFactory.get("book_entry");
      let text = "";
      if (this.mode === "commission_mode") {
        form["no_statement"] = "Y";
        text = "Processo di calcolo provvigionale avviato";
      } else {
        form["no_statement"] = "N";
        text = "Processo di generazione dei rendiconti avviato";
      }

      Repo.generate_broker_statements(form)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name, skipPrevCheckedItems = false) {
      if (
        !this.salesmen_ids.length ||
        !this.salesman_id ||
        !this.filter.byCalendar.from ||
        !this.filter.byCalendar.to
      ) {
        let missing = {};
        missing.produttore = !this.salesmen_ids.length;
        missing.intestazione = !this.salesman_id;
        missing.registrazione_dal = !this.filter.byCalendar.from;
        missing.registrazione_al = !this.filter.byCalendar.to;
        let msg = `Devi selezionare ${Object.keys(missing)
          .filter((key) => missing[key])
          .join(", ")}`;
        this.$showSnackbar({
          preset: "info",
          text: msg,
          showAction: false,
        });
      } else {
        // #640
        // TODO: effettuare una chiamata di ricerca dei rendiconti già esistenti per sapere se un altro qualsiasi rendiconto di quello stesso produttore (quello in “Intestazione documenti prodotti”) copre le stesse date del range scelto. Se no, tutto a posto, se sì, mostrare modale con avviso “Esiste già un altro rendiconto per il periodo scelto, vuoi continuare?”, se sì tutto a posto, se no, nascondere i risultati e mostrare di nuovo i filtri di ricerca.
        const RepoBrokerStatement = RepositoryFactory.get("broker_statement");
        const queryString = `byBroker[id]=${this.salesman_id}&byBetweenFromTo=${this.filter.byCalendar.from},${this.filter.byCalendar.to}`;
        RepoBrokerStatement.index(queryString).then((response) => {
          if (response.data.data.length) {
            this.showConfirm({
              yesCallback: () => {
                this.fetchData(name, skipPrevCheckedItems);
              },
              noCallback: () => {
                this.resetPage();
              },
              title: "Conferma",
              message: `Esistono già rendiconti in questo range (${this.filter.byCalendar.from}, ${this.filter.byCalendar.to}). Procedere comunque?`,
              yesLabel: "PROCEDI",
              yesVariant: "outline-lisaweb",
              noLabel: "ANNULLA",
            });
          } else {
            this.fetchData(name, skipPrevCheckedItems);
          }
        });
        // Fintanto che non esiste un filtro adatto al TODO qui sopra...
        // this.fetchData(name);
      }
    },
    fetchData(name, skipPrevCheckedItems = false) {
      // some handles for the fetch call...
      let args = { items: { handle: null, params: [] }, verb: {} };
      // 1. items handle
      args.items.handle = this.manipolaItems;
      let previous = this.filter.byCalendar.from;
      if (this.arretrati) {
        this.filter.byCalendar.from = "1900-01-01";
      }
      // TODO: nuovo filtro per escludere dal ricalcolo provvigionale
      // compagnie e i rami indicati nell’anagrafica Compagnie
      // if (this.filter.byExclusion) {
      //   // byInsurer[exclude_calc_vehicle_branch|exclude_calc_extra_vehicle_branch|exclude_calc_life_branch]=N
      //   this.filter.byInsurer.exclude_calc_vehicle_branch = "N";
      //   this.filter.byInsurer.exclude_calc_extra_vehicle_branch = "N";
      //   this.filter.byInsurer.exclude_calc_life_branch = "N";
      // } else {
      //   this.filter.byInsurer.exclude_calc_vehicle_branch = null;
      //   this.filter.byInsurer.exclude_calc_extra_vehicle_branch = null;
      //   this.filter.byInsurer.exclude_calc_life_branch = null;
      // }

      // DEBUG
      // this.filter.bySalesman = {};
      // this.filter.bySalesman.id = this.salesman_id;

      if (this.calculus_type) {
        // 2. verb handle, a replacement for index
        args.verb.name = "summary";
        args.verb.alias = "index";
        this.filter.byCommissionApplied = {};
        // enable BE calculus
        this.filter.byCommissionApplied.id = this.salesmen_ids.join(",");
        // do not add broker to query string
        this.filter.byBroker = {};
      } else {
        // add broker to query string
        this.filter.byBroker = {};
        // do not add byCommissionApplied to query string
        this.filter.byCommissionApplied = {};

        this.filter.byBroker.id = this.salesmen_ids.join(",");
      }
      let criteria = this.filter;
      // store the filter
      this.saveFilterByName({ name, criteria });
      // save for report..
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // this.removePaginationByName(name);
      // store the pagination 1000
      // criteria = { perPage: 1000, currentPage: 1}
      // this.savePaginationByName({ name, criteria});

      if (!skipPrevCheckedItems) {
        // keep the previous checked items
        this.keepPrevCheckedItemIds();
      } else {
        this.prevCheckedItemIds = [];
      }
      // reset checkAll checkbox
      this.$refs[this.tableRef].checkAll = false;
      this.$refs[this.tableRef].items = [];
      // reset readonly
      this.$refs[this.tableRef].isTableReadonly = false;
      // fetch data
      this.$refs[this.tableRef].fetch(args).then(() => {
        // set summary.ritenuta_perc from first item's broker.attributables.WHT
        // if (this.$refs[this.tableRef].items.length) {
        //   // NOTE nel caso Calcola con Lisaweb, item non ha la proprietà "brokers", quindi metto a 0
        //   this.summary.ritenuta_perc = this.$refs[this.tableRef].items[0]
        //     .brokers
        //     ? this.$refs[this.tableRef].items[0].brokers[0].attributables.WHT
        //       ? this.$refs[this.tableRef].items[0].brokers[0].attributables.WHT
        //       : 0
        //     : 0;
        // }
        // setChangedIds
        if (this.calculus_type == 1) {
          this.setChangedIds(this.$refs[this.tableRef].items);
          // // REVIEW mettere is_confirmed = Y??? o solo per calculus_type = 1
          // this.setConfirmedRows(this.$refs[this.tableRef].items);
        }
        // if(this.arretrati) {
        this.filter.byCalendar.from = previous;
        // }
        // this.resetPage();
        // #986: reimpostare i check come erano, se ce ne sono
        if (this.prevCheckedItemIds?.length) {
          this.setPrevCheckedItemIds();
        } else {
          // default: pre-select all
          this.$refs[this.tableRef].checkAll = true;
          this.$refs[this.tableRef].selectAll();
        }
      });
      this.showCollapse1 = false;
    },
    keepPrevCheckedItemIds() {
      this.prevCheckedItemIds = this.$refs[this.tableRef].items
        .filter((e) => e.check)
        .map((e) => e.id);
    },
    setPrevCheckedItemIds() {
      for (const id of this.prevCheckedItemIds) {
        let found = this.$refs[this.tableRef].items.find((e) => e.id === id);
        if (found) {
          found.check = true;
        }
      }
      if (
        this.prevCheckedItemIds.length &&
        this.prevCheckedItemIds.length ===
          this.$refs[this.tableRef].items.length
      ) {
        this.$refs[this.tableRef].checkAll = true;
      } else {
        this.$refs[this.tableRef].checkAll = false;
      }
    },
    manipolaItems(data) {
      // sort by "color": purple, white, pink
      // split data into subsets, sort each by book_date, then concat back
      let purples, whites, pinks;
      purples = data
        .filter(
          (item) =>
            // !item.insurance_ancillaries.length &&
            item.various_accountings &&
            item.various_accountings.length &&
            // item.various_accountings[0].code !== "FC"
            item.various_accountings[0].code === "CP"
        )
        .sort((a, b) => a.book_date.localeCompare(b.book_date));
      pinks = data
        .filter(
          (item) =>
            moment(item.book_date).diff(moment(this.filter.byCalendar.from)) < 0
        )
        .sort((a, b) => a.book_date.localeCompare(b.book_date));
      // not purples and not pinks...
      whites = data
        .filter(
          (item) =>
            !(
              // !item.insurance_ancillaries.length &&
              (
                item.various_accountings &&
                item.various_accountings.length &&
                // item.various_accountings[0].code !== "FC"
                item.various_accountings[0].code === "CP"
              )
            ) &&
            !(
              moment(item.book_date).diff(moment(this.filter.byCalendar.from)) <
              0
            )
        )
        .sort((a, b) => a.book_date.localeCompare(b.book_date));
      let sortedData = [...purples, ...whites, ...pinks];
      // console.debug(
      //   `manipolaItems: before: ${data.length}, after: ${sortedData.length}`
      // );
      if (data.length !== sortedData.length) {
        this.$showSnackbar({
          preset: "info",
          text: "Non è possibile ordinare correttamente tutti i dati per colore",
          showAction: false,
        });
        sortedData = data;
      }
      // negativizzo gli acconti: se VariousAccounting è AC (id = 3), allora metto il segno - al gross

      return sortedData.map((item) => {
        if (
          item.various_accountings &&
          item.various_accountings.length &&
          item.various_accountings[0].code === "AC"
        ) {
          item.gross *= -1;
        }
        if (item.is_confirmed.value === "Y") {
          item.purchase_commission = item.saler_prov_purchase;
          item.take_commission = item.saler_prov_take;
          item.insurer_fee = item.saler_fee_take;
        }
        // console.log("Item: ", item);
        // aggiungo campi "volanti" reattivi per modificare...
        // this.$set(item, "take_commission", null);
        // this.$set(item, "purchase_commission", null);
        return item;
      });
    },
    calculatePercentage(amount, perc) {
      return parseFloat(((amount * perc) / 100).toFixed(2));
    },
    resetPage() {
      this.changedIds = [];
      // reset selected rows
      this.$refs[this.tableRef].selectedRows = [];
      // remove all checks
      this.$refs[this.tableRef].unselectAll();
      this.$refs[this.tableRef].checkAll = false;
      // enable input fields and checkboxes
      this.$refs[this.tableRef].isTableReadonly = false;
      // hide BrokerStatement button
      // this.canBrokerStatement = false;
      // hide BrokerStatement section
      this.canShowBrokerStatement = false;
      // reset form
      this.form.invoice_number = null;
      this.form.invoice_period = null;
    },
    onClearFilter(name) {
      this.myToggle = false;
      // this.$nextTick(() => {
      this.resetFilter();
      // reset salesman_id
      this.salesmen_ids = [];
      this.salesman_id = null;
      // reset arretrati
      this.arretrati = false;
      this.resetPagination();
      // remove from store
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // clear the sortBy flag
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // fetch data
      // // this.$refs[this.tableRef].fetch();
      // NOTE: since salesman_id is mandatory, cannot fetch (with no filter), but must clear items instead!
      // this.onSearch(name);
      // reset changedIds
      this.changedIds = [];
      // reset items
      this.$refs[this.tableRef].items = [];
      // reset checkAll checkbox
      // this.$refs[this.tableRef].checkAll = false;
      this.resetPage();
      // });
    },
    onReset() {
      this.resetPage();
      this.$refs[this.tableRef].selectAll();
      this.$refs[this.tableRef].checkAll = true;
      this.onSearch(this.filterName, true);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {
      removePaginationByName: "removeByName",
      savePaginationByName: "saveByName",
    }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  beforeMount() {
    this.salesmen = this.getSalesmen();
    this.insurers = this.getInsurers();
    // do not AUTOMATIC FETCH
    this.removeFilterByName(this.filterName);
  },
  computed: {
    flds() {
      return this.selectableFields[
        this.$store.state.auth.settings.attribute_STATMOD_value
      ];
    },
    selRows() {
      return this.$refs[this.tableRef].selectedRows;
    },
    isUpd() {
      return this.isUpdating;
    },
    isSav() {
      return this.isSaving;
    },
    intProd() {
      // options per la seconda tendina dei produttori
      return this.getSalesmen().filter((e) =>
        this.salesmen_ids.includes(e.value)
      );
    },
    smid() {
      return this.salesman_id;
    },
    smids() {
      return this.salesmen_ids;
    },
    // hasItems() {
    //   return this.$refs[this.tableRef] && this.$refs[this.tableRef].items.length
    // }
  },
};
</script>
<style lang="scss" scoped>
table.totals {
  background-color: white;
  width: 100%;
  table-layout: fixed;
  th {
    text-align: center;
  }
  td {
    text-align: right;
    font-weight: bold;
  }
}
table.summary {
  width: 50%;
  border-collapse: collapse;
}
table,
td,
th {
  border: 1px solid #dee2e6;
}
table tr th,
td {
  width: 30%;
  padding: 2px 4px;
  text-align: left;
}
th[scope="row"] {
  background: orange !important;
  color: white !important;
  text-transform: uppercase;
  width: 70%;
}
th[scope="col"] {
  text-transform: uppercase;
}
</style>
