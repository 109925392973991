<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in dtlFlds"
          :key="index"
          :title="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
        <div v-if="details.is_collected.value === 'N'">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-card header="Incassa Importo" header-tag="header" class="mt-2">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="occurred_at"
                      name="Data"
                      label="Data"
                      v-model="date"
                      :rules="{ required: true }"
                    />
                  </div>

                  <div class="form-group col-md-3">
                    <base-select
                      name="treasury"
                      label="Seleziona una cassa"
                      v-model="treasury"
                      :options="treasury_options"
                      @input="onInputTreasury"
                    />
                  </div>

                  <div class="form-group col-md-3">
                    <base-checkbox
                      name="importo differente"
                      label=""
                      v-model="importo_differente"
                      :chk_val="true"
                      :unchk_val="false"
                      :isSwitch="true"
                      groupLabel="Importo differente"
                      :disabled="amount_due < 0 || !treasury"
                    />
                    <!-- :disabled="details.amount_due < 0 || !treasury" -->
                  </div>

                  <div
                    v-if="treasury && importo_differente"
                    class="form-group col-md-3"
                  >
                    <base-currency
                      name="importo"
                      vid="gross"
                      label="Nuovo importo"
                      v-model="importo"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>

            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onUpdate)"
                type="button"
                variant="lisaweb"
                size="sm"
                :disabled="
                  !treasury || (importo_differente && importo === 0) || invalid
                "
              >
                Incassa
              </b-button>
            </div>
          </validation-observer>
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { mapGetters } from "vuex";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailsCard from "@/components/DetailsCard";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseCurrency from "@/components/form/BaseCurrency";
import { toInfoData, toTotalizers } from "@/utils/transforms";
import { toLocaleCurrency } from "@/utils/strings";
import { today } from "@/utils/dates";

export default {
  mixins: [FormMixin, ShowMixin, ShortcutMixin /* , ConfirmModalMixin */],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
    BaseSelect,
    BaseCheckbox,
    BaseCurrency,
    BaseDatepicker,
  },
  data() {
    return {
      details: null,
      treasury: null,
      importo_differente: false,
      date: today(),
      importo: 0,
      repository: "broker_statement",
      resource: "broker_statements", // REVIEW: add anywhere if required by check permissions on show
      isLoading: true,
      detailFields: {
        dati: {
          header: "Dati",
          edit: false,
          repository: "broker_statement",
          fields: [
            {
              label: this.getDictionary("salesman"),
              value: "salesman",
              virtual: (detail) => {
                return this.toInfoData(detail.broker, "broker", 0, null, "\n");
              },
            },
            {
              label: "Data creazione",
              value: "created_date",
              type: "datepicker",
            },
            {
              label: "Data inizio",
              value: "from",
              type: "datepicker",
            },
            {
              label: "Data fine",
              value: "to",
              type: "datepicker",
            },
            {
              label: "Incassato",
              value: "is_collected.text",
            },
            {
              label: "Totale da incassare",
              value: "amount_due",
              virtual: (detail) => {
                // return parseFloat(
                //   (
                //     parseFloat(detail.gross) -
                //     (parseFloat(detail.take) +
                //       parseFloat(detail.purchase) -
                //       parseFloat(detail.deduction) +
                //       parseFloat(detail.fees))
                //   ).toFixed(2)
                // );
                return toLocaleCurrency(
                  parseFloat(detail.gross) -
                    (parseFloat(detail.take) +
                      parseFloat(detail.purchase) -
                      parseFloat(detail.deduction) +
                      parseFloat(detail.fees))
                );
              },
            },
            {
              label: "Totalizzatori",
              value: "totalizers",
              virtual: (detail) => {
                // return this.toTotalizers(detail.totalizers);
                return this.toTotalizers(detail); // new fashion
              },
            },
          ],
        },
      },
      // da questa modale, devo poter salvare un form
      form: {},
      treasury_options: [],
    };
  },
  methods: {
    toInfoData,
    toTotalizers,
    toLocaleCurrency,
    fetchForm() {
      let queryString = "relations/byBroker";
      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          if (data === false) return;
          this.details = data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    onCreate(payload, repository, title) {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(repository);
      Repo.store(payload).then((response) => {
        let id = response.data.data.id;
        this.fetchForm().then(() => {
          this.$set(this.detailFields[`${title}`], `id`, id);
          this.$set(this.detailFields[`${title}`], `create`, false);
          this.$set(this.detailFields[`${title}`], `edit`, true);
          this.$emit("fetch");
          this.isLoading = false;
        });
      });
    },
    onUpdate() {
      // this.$showSnackbar({
      //   preset: "info",
      //   text: `Updating id: ${id}`,
      // });

      // - creare sempre un movimento contabile di tipo produttore (id 2)
      // con solo cassa scelta con importo indicato (se switch spento, importo già presente in “totale da versare”

      /* Request URL: http://frontoffice.lisa3.love/api/book_entries
      Request Method: POST

      } */
      const RepoBookEntry = RepositoryFactory.get("book_entry");
      const RepoEntryDetail = RepositoryFactory.get("entry_detail");
      this.isLoading = true;
      let p1 = {
        book_date: this.date,
        effective_date: this.date,
        sheet_date: this.date,
        registry: {},
        is_taxable: "N",
        is_statement_excluded: "Y", // #773: movimento con cassa scelta da tendina, deve essere salvato con "escludi da rendiconto" già a Y
        title: "incasso",
        broker: {
          [this.details.broker_id]: {},
        },
        net: 0,
        tax: 0,
        // gross: this.importo_differente ? this.importo : this.details.amount_due,
        gross: this.importo_differente ? this.importo : this.amount_due,
        agency_prov_take: 0,
        agency_prov_purchase: 0,
        saler_prov_take: 0,
        saler_prov_purchase: 0,
        various_accounting: {
          2: {},
        },
      };
      // console.debug(p1);
      let bookEntryId = null;
      RepoBookEntry.store(p1)
        .then((response) => {
          bookEntryId = response.data.data.id;
          /* Request URL: http://frontoffice.lisa3.love/api/entry_details
        Request Method: POST
        */
          let p2 = {
            book_entry_id: bookEntryId,
            treasury_id: this.treasury,
            gross: this.importo_differente
              ? this.importo
              : // : this.details.amount_due,
                this.amount_due,
            detail_date: this.date,
          };
          // console.debug(p2);
          RepoEntryDetail.store(p2)
            .then(() => {
              // - Update rendiconto: incassato = Y (nessun’altra modifica)
              let p3 = {
                is_collected: "Y",
                broker_id: this.details.broker_id,
                from: this.details.from,
                to: this.details.to,
                invoice_number: this.details.invoice_number,
                invoice_period: this.details.invoice_period,
                // totalizers: this.details.totalizers, // #1112-no-totalizers
                broker_wht: this.details.broker_wht,
                gross: this.details.gross,
                deferred: this.details.deferred,
                deposit: this.details.deposit,
                purchase: this.details.purchase,
                take: this.details.take,
                fees: this.details.fees,
                purchase_tax: this.details.purchase_tax,
                take_tax: this.details.take_tax,
                deduction: this.details.deduction,
                premium: this.details.premium,
                // amount_due: this.details.amount_due,
                book_entry: {
                  id: [bookEntryId],
                },
              };
              this.update(this.repository, this.resourceId, p3)
                .then(() => {
                  // - Se switch acceso e input “quanto ti ha versato” diverso da “totale da versare”, salvare anche un altro movimento contabile, sempre id 2 vari, con la differenza in “provv inc prod” (può essere negativa): quanto ti ha versato - (meno) totale da versare
                  // [lordo è 0, non ha casse]
                  if (
                    this.importo_differente &&
                    // parseFloat(this.importo) !== this.details.amount_due
                    parseFloat(this.importo) !== this.amount_due
                  ) {
                    let diff = parseFloat(
                      (
                        parseFloat(this.importo) -
                        // parseFloat(this.details.amount_due)),
                        parseFloat(this.amount_due)
                      ).toFixed(2)
                    );
                    let p4 = {
                      book_date: this.date,
                      effective_date: this.date,
                      sheet_date: this.date,
                      registry: {},
                      is_taxable: "N",
                      title: "incasso differenza",
                      broker: {
                        [this.details.broker_id]: {},
                      },
                      // #777: popolare il campo dell'imponibile e lordo con segno opposto a saler_prov_take
                      //      MA NON DEVE PIU' SALVARE il CAMPO DELLA PROVVIGIONE
                      net: -1 * diff,
                      /* parseFloat((parseFloat(this.importo) -
                          // parseFloat(this.details.amount_due)),
                          parseFloat(this.amount_due)).toFixed(2)), */
                      tax: 0,
                      gross: -1 * diff,
                      /* parseFloat((parseFloat(this.importo) -
                          // parseFloat(this.details.amount_due)),
                          parseFloat(this.amount_due)).toFixed(2)), */
                      agency_prov_take: 0,
                      agency_prov_purchase: 0,
                      saler_prov_take: 0,
                      // parseFloat(this.importo) -
                      // parseFloat(this.details.amount_due),
                      saler_prov_purchase: 0,
                      various_accounting: {
                        2: {},
                      },
                    };
                    RepoBookEntry.store(p4)
                      .then(() => {
                        this.$showSnackbar({
                          preset: "success",
                          text: `Incasso eseguito con successo`,
                        });
                        this.isLoading = false;
                        this.details.is_collected.value === "Y"; // così nasconde la seconda card col form...
                        this.$emit("fetch");
                        this.$emit("close");
                      })
                      .catch((error) => {
                        this.isLoading = false;
                        let errMsg = this.$getErrorMessage(error);
                        this.$showSnackbar({
                          preset: "error",
                          text: `${errMsg}`,
                        });
                      });
                  } else {
                    this.$showSnackbar({
                      preset: "success",
                      text: `Incasso eseguito con successo`,
                    });
                    this.isLoading = false;
                    this.details.is_collected.value === "Y"; // così nasconde la seconda card col form...
                    this.$emit("fetch");
                    this.$emit("close"); // chiude la modale
                  }
                })
                .catch((error) => {
                  this.isLoading = false;
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                });
            })
            .catch((error) => {
              this.isLoading = false;
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onInputTreasury(val) {
      // console.debug("selected trasury: ", val);
      if (!val) {
        this.importo_differente = false;
        this.importo = 0;
      }
    },
    // toTotalizers(array, sep = "<br/>") {
    //   let t = [];
    //   if (Array.isArray(array)) {
    //     array.forEach((e) => {
    //       for (const [k, v] of Object.entries(e)) {
    //         t.push(`${k}: ${this.toLocaleCurrency(v)}`);
    //       }
    //     });
    //   }
    //   return t.join(sep);
    // },
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
    }),
  },
  computed: {
    dtlFlds() {
      return this.detailFields;
    },
    amount_due() {
      return (
        parseFloat(this.details.gross) -
        (parseFloat(this.details.take) +
          parseFloat(this.details.purchase) -
          parseFloat(this.details.deduction) +
          parseFloat(this.details.fees))
      );
    },
  },
  beforeMount() {
    this.treasury_options = this.getAllTreasuries().filter(
      (e) =>
        e.sector.is_abstract.value === "N" &&
        e.sector.is_system.value === "N" &&
        e.sector.is_agency.value === "N"
    );
  },
  mounted() {
    this.isLoading = true;
    this.fetchForm().then(() => {});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
