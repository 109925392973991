<template>
  <div class="mt-1 mx-3">
    <salesmen />
  </div>
</template>

<script>
import Salesmen from "@/components/accounting/accounting-prod/salesmen/index.vue";
export default {
  props: {
    msg: String,
  },
  components: {
    Salesmen,
  },
  created() {},
};
</script>
