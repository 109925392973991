<template>
  <div class="mx-1">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" v-model="visible">
      <b-form @submit.prevent="loadData(1)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-4">
              <base-select
                name="code"
                type="text"
                label="Compagnia"
                v-model="filter.byInsurer.id"
                :options="insurers"
                @input="onInputInsurer"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                :disabled="!filter.byInsurer.id"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter()"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <div v-if="showForm && visibleStates[0] && insurerHasFee">
            <b-card header="Diritti" class="mb-2">
              <b-card-text>
                <b-row>
                  <div class="col-md-3">
                    <base-currency
                      name="fee"
                      vid="fee"
                      label="Percentuale"
                      v-model="fee"
                      placeholder="Percentuale"
                      :options="{
                        currencyDisplay: 'percent',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                  <div class="form-group col-md-3 align-self-end">
                    <b-button
                      class="btn-create"
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      @click="onSaveFee"
                      :disabled="onSavingFee"
                    >
                      <b-spinner
                        small
                        type="grow"
                        v-show="onSavingFee"
                      ></b-spinner>
                      Salva Percentuale</b-button
                    >
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
          </div>
          <div v-if="showForm">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <b-form @submit.prevent="">
                <b-card
                  :header="
                    'Produttore: ' + getStringValue('salesmen', salesman_id)
                  "
                  header-tag="header"
                >
                  <b-card-text>
                    <div>
                      <b-collapse id="accordion-1" v-model="visibleStates[0]">
                        <div class="mb-2">
                          <span class="level-title">:: Rami ::</span>
                          Compagnia:
                          {{
                            getStringValue(
                              "insurers",
                              filter.byInsurer.id,
                              "code"
                            ) +
                            " - " +
                            getStringValue(
                              "insurers",
                              filter.byInsurer.id,
                              "title"
                            )
                          }}
                          <b-button
                            variant="lisaweb"
                            @click="onCopy"
                            :disabled="
                              !form['level1'].pdata.length &&
                              !form['level1'].npdata.length
                            "
                            >copia capitolato</b-button
                          >
                        </div>
                        <table>
                          <thead>
                            <th class="button">Azione</th>
                            <th class="button">Gestisci Rischi</th>
                            <th v-if="showID">ID</th>
                            <th>Codice Ramo</th>
                            <th>Descrizione</th>
                            <th class="pivot">Calcolo Acquisto (%)</th>
                            <th class="pivot">Calcolo Incasso (%)</th>
                          </thead>
                          <tbody>
                            <!-- pdata -->
                            <tr
                              v-if="
                                !form['level1'].pdata.length &&
                                !form['level1'].npdata.length
                              "
                            >
                              <td colspan="6">Non ci sono rami associati</td>
                            </tr>

                            <tr
                              v-for="(data, index) in form['level1'].pdata"
                              :key="'pdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <div class="action-buttons">
                                  <b-button
                                    variant="lisaweb"
                                    @click="updateData(1, index)"
                                    :disabled="
                                      invalid || isDisabled(1, 'pdata', index)
                                    "
                                    >aggiorna</b-button
                                  >
                                  <b-button
                                    variant="lisaweb"
                                    @click="deleteData(1, index)"
                                    class="ml-2"
                                    title="elimina"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </b-button>
                                </div>
                              </td>
                              <td>
                                <b-button
                                  variant="primary"
                                  @click="loadData(2, data.id)"
                                  >rischi</b-button
                                >
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.code }}
                                <!-- {{
                                  getStringValue(
                                    "branches",
                                    data.risk_branch_id,
                                    "code"
                                  )
                                }} -->
                              </td>
                              <td>
                                {{ data.title }}
                                <!-- {{
                                  getStringValue(
                                    "branches",
                                    data.risk_branch_id,
                                    "title"
                                  )
                                }} -->
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level1_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level1_pdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level1'].pdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          1,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                    <!-- :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }" -->
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level1_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level1_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level1'].pdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          1,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level1_pdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level1_pdata_' + index
                                      "
                                      v-model="
                                        form['level1'].pdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          1,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level1_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level1_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level1'].pdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          1,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                            </tr>
                            <!-- npdata -->
                            <tr
                              v-for="(data, index) in form['level1'].npdata"
                              :key="'npdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <b-button
                                  variant="lisaweb"
                                  @click="storeData(1, index)"
                                  :disabled="
                                    invalid || isDisabled(1, 'npdata', index)
                                  "
                                  >salva</b-button
                                >
                              </td>
                              <td>
                                <b-button
                                  variant="primary"
                                  @click="loadData(2, data.id)"
                                  >rischi</b-button
                                >
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.code }}
                                <!-- {{
                                  getStringValue(
                                    "branches",
                                    data.risk_branch_id,
                                    "code"
                                  )
                                }} -->
                              </td>
                              <td>
                                {{ data.title }}
                                <!-- {{
                                  getStringValue(
                                    "branches",
                                    data.risk_branch_id,
                                    "title"
                                  )
                                }} -->
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level1_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level1_npdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level1'].npdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          1,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level1_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level1_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level1'].npdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          1,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level1_npdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level1_npdata_' + index
                                      "
                                      v-model="
                                        form['level1'].npdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          1,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level1_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level1_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level1'].npdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          1,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- bottone per salva tutto? -->
                        <div class="py-2 mb-4">
                          <b-button
                            @click="saveAll(1)"
                            type="button"
                            :disabled="invalid || areAllDisabled(1)"
                            variant="lisaweb"
                            size="sm"
                          >
                            Salva Tutti
                          </b-button>
                        </div>
                      </b-collapse>

                      <b-collapse id="accordion-2" v-model="visibleStates[1]">
                        <b-row>
                          <b-col align="left">
                            <b-button
                              variant="light"
                              title="livello superiore"
                              class="mb-1"
                              @click="
                                collapse(2);
                                expand(1);
                              "
                              ><b-icon
                                icon="backspace"
                                aria-hidden="true"
                              ></b-icon
                            ></b-button>
                            <span class="level-title">:: Rischi ::</span>
                            Compagnia:
                            {{
                              getStringValue(
                                "insurers",
                                filter.byInsurer.id,
                                "code"
                              ) +
                              " - " +
                              getStringValue(
                                "insurers",
                                filter.byInsurer.id,
                                "title"
                              )
                            }}
                            :: Ramo:
                            {{
                              getStringValue(
                                "branches",
                                filter.byRiskBranch.id,
                                "code"
                              ) +
                              " - " +
                              getStringValue(
                                "branches",
                                filter.byRiskBranch.id,
                                "title"
                              )
                            }}</b-col
                          >
                          <!-- <b-button @click="loadData(3, 1, 1)">scorpori</b-button> -->
                          <!-- <b-col align="right">
                            <b-button
                              variant="light"
                              title="livello superiore"
                              class="mb-1"
                              @click="
                                collapse(2);
                                expand(1);
                              "
                              ><b-icon
                                icon="backspace"
                                aria-hidden="true"
                              ></b-icon
                            ></b-button>
                          </b-col> -->
                        </b-row>
                        <table>
                          <thead>
                            <!-- <th class="button">Ignora scorpori</th> -->
                            <th class="button">Azione</th>
                            <th class="button">Gestisci Scorpori</th>
                            <th v-if="showID">ID</th>
                            <th>Codice Rischio</th>
                            <th>Descrizione</th>
                            <th class="pivot">Calcolo Acquisto (%)</th>
                            <th class="pivot">Calcolo Incasso (%)</th>
                          </thead>
                          <tbody>
                            <tr
                              v-if="
                                !form['level2'].pdata.length &&
                                !form['level2'].npdata.length
                              "
                            >
                              <td colspan="6">
                                Non ci sono prodotti associati
                              </td>
                            </tr>
                            <!-- pdata -->
                            <tr
                              v-for="(data, index) in form['level2'].pdata"
                              :key="'pdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <div class="action-buttons">
                                  <b-button
                                    variant="lisaweb"
                                    @click="
                                      filter.byInsuranceRisk.id = data.id;
                                      updateData(2, index);
                                    "
                                    :disabled="
                                      invalid || isDisabled(2, 'pdata', index)
                                    "
                                    >aggiorna</b-button
                                  >
                                  <b-button
                                    class="ml-2"
                                    variant="lisaweb"
                                    @click="deleteData(2, index)"
                                    title="elimina"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </b-button>
                                </div>
                              </td>
                              <td>
                                <!-- <b-button
                                  variant="primary"
                                  @click="
                                    loadData(3, filter.byRiskBranch.id, data.id)
                                  "
                                  >scorpori</b-button
                                > -->
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.code }}
                              </td>
                              <td>
                                {{ data.title }}
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level2_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level2_pdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level2'].pdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          2,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level2_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level2_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level2'].pdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          2,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level2_pdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level2_pdata_' + index
                                      "
                                      v-model="
                                        form['level2'].pdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          2,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level2_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level2_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level2'].pdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          2,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <!-- <td>
                                <base-checkbox
                                  :name="'ignore_' + index"
                                  :vid="'ignore_' + index"
                                  v-model="form['level2'].pdata[index].ignore"
                                  @input="enableButton(2, 'pdata', index, ...arguments)"
                                />
                              </td> -->
                            </tr>
                            <!-- npdata -->
                            <tr
                              v-for="(data, index) in form['level2'].npdata"
                              :key="'npdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <b-button
                                  variant="lisaweb"
                                  @click="
                                    filter.byInsuranceRisk.id = data.id;
                                    storeData(2, index);
                                  "
                                  :disabled="
                                    invalid || isDisabled(2, 'npdata', index)
                                  "
                                  >salva</b-button
                                >
                              </td>
                              <td>
                                <!-- <b-button
                                  variant="primary"
                                  @click="
                                    loadData(3, filter.byRiskBranch.id, data.id)
                                  "
                                  >scorpori</b-button
                                > -->
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.code }}
                                <!-- {{ getStringValue('risks', filter.byInsuranceRisk.id, 'code') }} -->
                              </td>
                              <td>
                                {{ data.title }}
                                <!-- {{ getStringValue('risks', filter.byInsuranceRisk.id, 'title') }} -->
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level2_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level2_npdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level2'].npdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          2,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level2_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level2_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level2'].npdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          2,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level2_npdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level2_npdata_' + index
                                      "
                                      v-model="
                                        form['level2'].npdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          2,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level2_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level2_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level2'].npdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          2,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- bottone per salva tutto? -->
                        <div class="py-2 mb-4">
                          <b-button
                            @click="saveAll(2)"
                            type="button"
                            :disabled="invalid || areAllDisabled(2)"
                            variant="lisaweb"
                            size="sm"
                          >
                            Salva Tutti
                          </b-button>
                        </div>
                      </b-collapse>

                      <b-collapse id="accordion-3" v-model="visibleStates[2]">
                        <b-row>
                          <b-col align="left">
                            <b-button
                              variant="light"
                              title="livello superiore"
                              class="mb-1"
                              @click="
                                collapse(3);
                                expand(2);
                              "
                              ><b-icon
                                icon="backspace"
                                aria-hidden="true"
                              ></b-icon
                            ></b-button>
                            <span class="level-title">:: Scorpori ::</span>
                            Compagnia:
                            {{
                              getStringValue(
                                "insurers",
                                filter.byInsurer.id,
                                "code"
                              ) +
                              " - " +
                              getStringValue(
                                "insurers",
                                filter.byInsurer.id,
                                "title"
                              )
                            }}
                            :: Ramo:
                            {{
                              getStringValue(
                                "branches",
                                filter.byRiskBranch.id,
                                "code"
                              ) +
                              " - " +
                              getStringValue(
                                "branches",
                                filter.byRiskBranch.id,
                                "title"
                              )
                            }}
                            :: Prodotto:
                            {{
                              getStringValue(
                                "products",
                                filter.byInsuranceRisk.id,
                                "code"
                              ) +
                              " - " +
                              getStringValue(
                                "products",
                                filter.byInsuranceRisk.id,
                                "title"
                              )
                            }}</b-col
                          >
                          <!-- <b-col align="right">
                            <b-button
                              variant="light"
                              title="livello superiore"
                              class="mb-1"
                              @click="
                                collapse(3);
                                expand(2);
                              "
                              ><b-icon
                                icon="backspace"
                                aria-hidden="true"
                              ></b-icon
                            ></b-button>
                          </b-col> -->
                        </b-row>
                        <table>
                          <thead>
                            <th class="button">Azione</th>
                            <th v-if="showID">ID</th>
                            <th>Descrizione garanzia</th>
                            <th class="pivot">Calcolo Acquisto (%)</th>
                            <th class="pivot">Calcolo Incasso (%)</th>
                          </thead>
                          <tbody>
                            <tr
                              v-if="
                                !form['level3'].pdata.length &&
                                !form['level3'].npdata.length
                              "
                            >
                              <td colspan="4">
                                Non ci sono scorpori associati
                              </td>
                            </tr>
                            <!-- pdata -->
                            <tr
                              v-for="(data, index) in form['level3'].pdata"
                              :key="'pdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <div class="action-buttons">
                                  <b-button
                                    variant="lisaweb"
                                    @click="
                                      filter.byBreakdown.id = data.id;
                                      updateData(3, index);
                                    "
                                    :disabled="
                                      invalid || isDisabled(3, 'pdata', index)
                                    "
                                    >aggiorna</b-button
                                  >
                                  <b-button
                                    class="ml-2"
                                    variant="lisaweb"
                                    @click="deleteData(3, index)"
                                    title="elimina"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </b-button>
                                </div>
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.title }}
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level3_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level3_pdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level3'].pdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          3,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level3_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level3_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level3'].pdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          3,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level3_pdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level3_pdata_' + index
                                      "
                                      v-model="
                                        form['level3'].pdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          3,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level3_pdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level3_pdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level3'].pdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          3,
                                          'pdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <!-- <td>
                                <base-checkbox
                                  :name="'ignore_' + index"
                                  :vid="'ignore_' + index"
                                  v-model="form['level3'].pdata[index].ignore"
                                  @input="enableButton(3, 'pdata', index, ...arguments)"
                                />
                              </td> -->
                              <!-- <td>
                                <b-button variant="primary" @click="loadData(3, filter.byRiskBranch.id, data.id)">scorpori</b-button>
                              </td> -->
                            </tr>
                            <!-- npdata -->
                            <tr
                              v-for="(data, index) in form['level3'].npdata"
                              :key="'npdata' + index"
                            >
                              <td>
                                <!-- pdata: must update (not store) -->
                                <!-- npdata: must store (no update) -->
                                <b-button
                                  variant="lisaweb"
                                  @click="
                                    filter.byBreakdown.id = data.id;
                                    storeData(3, index);
                                  "
                                  :disabled="
                                    invalid || isDisabled(3, 'npdata', index)
                                  "
                                  >salva</b-button
                                >
                              </td>
                              <td v-if="showID">{{ data.id }}</td>
                              <td>
                                {{ data.title }}
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'purchase_commission_level3_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_commission_level3_npdata_' +
                                        index
                                      "
                                      v-model="
                                        form['level3'].npdata[index]
                                          .purchase_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          3,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="
                                        getRules('**.purchase_commission')
                                      "
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'purchase_calculation_type_level3_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'purchase_calculation_type_level3_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level3'].npdata[index]
                                          .purchase_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          3,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.purchase_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                              <td>
                                <b-row>
                                  <div class="col-md-4">
                                    <base-currency
                                      :name="
                                        'take_commission_level3_npdata_' + index
                                      "
                                      :vid="
                                        'take_commission_level3_npdata_' + index
                                      "
                                      v-model="
                                        form['level3'].npdata[index]
                                          .take_commission
                                      "
                                      type="text"
                                      label=""
                                      @keydown="
                                        enableButton(
                                          3,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :options="{
                                        locale: 'it-IT',
                                        currencyDisplay: 'percent',
                                        precision: 2,
                                      }"
                                      :rules="getRules('**.take_commission')"
                                    >
                                    </base-currency>
                                  </div>
                                  <div class="col-md-8">
                                    <base-radio
                                      :name="
                                        'take_calculation_type_level3_npdata_' +
                                        index
                                      "
                                      :vid="
                                        'take_calculation_type_level3_npdata_' +
                                        index
                                      "
                                      label=""
                                      v-model="
                                        form['level3'].npdata[index]
                                          .take_calculation_type
                                      "
                                      :options="calculation_types"
                                      @input="
                                        enableButton(
                                          3,
                                          'npdata',
                                          index,
                                          ...arguments
                                        )
                                      "
                                      :rules="
                                        getRules('**.take_calculation_type')
                                      "
                                    >
                                    </base-radio>
                                  </div>
                                </b-row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- bottone per salva tutto? -->
                        <div class="py-2 mb-4">
                          <b-button
                            @click="saveAll(3)"
                            type="button"
                            :disabled="invalid || areAllDisabled(3)"
                            variant="lisaweb"
                            size="sm"
                          >
                            Salva Tutti
                          </b-button>
                        </div>
                      </b-collapse>
                    </div>
                  </b-card-text>
                </b-card>
                <!-- bottone per salva tutto? -->
                <!-- <div class="py-2 mb-4">
                  <b-button
                    @click="handleSubmit(onSubmit)"
                    type="button"
                    :disabled="invalid"
                    variant="lisaweb"
                    size="sm"
                  >
                    Salva
                  </b-button>
                  <b-button
                    class="btn-reset"
                    type="button"
                    variant="lisaweb"
                    size="sm"
                    @click="$router.back()"
                    >Annulla
                  </b-button>
                </div> -->
              </b-form>
            </validation-observer>
          </div>
          <div v-else>
            {{ message2 }}
          </div>
        </div>
        <div v-else>
          {{ message1 }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
    <div>
      <copy-commissions-modal
        :opts="brokersOptions"
        title="Copia Capitolato"
        :text="`Per procedere è necessario selezionare uno o più produttori:
      <br />
      Compagnia:<b>${getStringValue('insurers', filter.byInsurer.id, 'code')} -
        ${getStringValue('insurers', filter.byInsurer.id, 'title')}</b>
      <br />
      Produttore:<b>
        ${getStringValue('salesmen', salesman_id)}
      </b>`"
        @copy="copy"
      />
    </div>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import CommissionsMixin from "@/mixins/CommissionsMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { mapGetters } from "vuex";
import common from "@/components/profile/brokers/common.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ShowMixin from "@/mixins/ShowMixin";

export default {
  mixins: [
    ShowMixin,
    FormMixin,
    CommissionsMixin,
    CustomFiltersMixin,
    ConfirmModalMixin,
  ],
  extends: common,
  data() {
    return {
      filter: this.initFilter(),
      repository: "commission",
      salesman_id: parseInt(this.$route.params.id),
      onSavingFee: false,
      message1: "Caricamento...",
      message2: "Nessun dato selezionato",
      showID: false,
      fee: 0.0,
      insurerHasFee: false,
      form: {
        level1: {
          pdata: [],
          npdata: [],
        },
        level2: {
          pdata: [],
          npdata: [],
        },
        level3: {
          pdata: [],
          npdata: [],
        },
      },
      calculation_types: [
        { value: 0, text: "Su premio" },
        { value: 1, text: "Su provvigione" },
      ],
      showForm: false,
      insurers: this.getInsurers(),
      buttons: {
        // keep enable/disable state for 'aggiorna' button
        level1: {
          pdata: {},
          npdata: {},
        },
        level2: {
          pdata: {},
          npdata: {},
        },
        level3: {
          pdata: {},
          npdata: {},
        },
      },
    };
  },
  props: {},
  components: {
    BaseIcon,
    BaseCurrency,
    BaseSelect,
    BaseRadio,
  },
  methods: {
    initFilter() {
      let init = {
        byInsurer: {
          id: null, // this.getInsurers()[0] ? this.getInsurers()[0].value: null, // default: the first
        },
        byRiskBranch: {
          id: null,
        },
        byInsuranceRisk: {
          id: null,
        },
        byBreakdown: {
          id: null,
        },
      };
      return init;
    },
    onInputInsurer(value) {
      if (value) {
        const selectedInsurer = this.getInsurers().find(
          (e) => e.value == value
        );
        if (selectedInsurer && selectedInsurer.use_fee === "N") {
          this.fee = 0.0;
          this.insurerHasFee = false;
        }
        if (selectedInsurer && selectedInsurer.use_fee === "Y") {
          this.insurerHasFee = true;
        }
        let queryString = "relations/byInsurer";
        this.fetchShowForm("broker", this.salesman_id, queryString).then(
          (response) => {
            const insurers = response.data.data.insurers;
            const pivotInsurer = insurers.find((e) => e.id == value);
            if (pivotInsurer) {
              this.fee = parseFloat(pivotInsurer.pivot.fee).toFixed(2);
            }
          }
        );
      } else {
        this.fee = 0.0;
        this.insurerHasFee = false;
      }
    },
    onClearFilter() {
      this.filter.byInsurer.id = null;
      this.showForm = false;
    },
    onCopy() {
      // TODO deve aprire la modale..
      this.$bvModal.show("copyCommissions");
      /* this.showConfirm({
        yesCallback: () => {
          this.copy();
        },
        // noCallback: () => {
        //   this.$bvModal.hide("createInsurancePaymentOptionsModal");
        // },
        noCallback: null,
        title: "Conferma",
        message: "Confermi?",
        yesLabel: "PROCEDI",
        yesVariant: "outline-lisaweb",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      }); */
    },
    copy(options) {
      this.showConfirm({
        yesCallback: () => {
          this.copyCommissions(options);
        },
        // noCallback: null,
        noCallback: () => {},
        title: "Conferma copia",
        message: `Stai per cancellare il capitolato di tutti i produttori scelti. Copiare?`,
        yesLabel: "COPIA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
      console.log(options);
    },
    copyCommissions(brokers) {
      let payload = {
        brokers: {
          //   id1: {},
          //   id2: {},
        },
      };
      for (const b of brokers) {
        payload.brokers[b] = {};
      }
      this.cloneData(this.salesman_id, this.filter.byInsurer.id, payload);
      // let payload = {
      //   source: this.salesman_id,
      //   destination: brokers,
      //   insurer_id: this.filter.byInsurer.id,
      // };
      console.log("payload", payload);
    },
    onSaveFee() {
      this.onSavingFee = true;
      const payload = {
        broker: {
          [this.salesman_id]: {
            fee: this.fee,
          },
        },
      };
      this.addtoInsurer(this.filter.byInsurer.id, "broker", payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Percentuale Modificata`,
          });
          this.onSavingFee = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.onSavingFee = false;
        });
    },
    addtoInsurer(insurer_id, relation, payload) {
      const Repo = RepositoryFactory.get("insurer");
      return Repo.pivot_store(insurer_id, relation, payload);
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
    }),
  },
  beforeMount() {
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    visible: {
      get() {
        return !this.showForm;
      },
      set(value) {
        this.showForm = !value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  th,
  td {
    border: 1px solid #e1ddda;
    border-left: none;
    border-right: none;
    border-collapse: collapse;
  }
  tr td,
  th {
    padding: 2px 4px;
    text-align: left;
    vertical-align: middle;
    &.pivot {
      width: 40%;
    }
    &.button {
      width: 4rem;
    }
  }
}

:deep(table .form-group) {
  margin-bottom: 0rem !important;
}

.level-title {
  font-weight: bold;
}
</style>
