<template>
  <div class="mt-1 mr-1 mb-5" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <!-- <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          :disabled="isLoading"
          @click="onOpenGenerateBrokerStatements('commission_mode')"
          >Effettua Calcoli Provvigionali</b-button
        > -->
        <!-- <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          :disabled="isLoading"
          @click="onOpenGenerateBrokerStatements('broker_statement_mode')"
          >Genera Rendiconti</b-button
        > -->
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          :disabled="isLoading || !uniquePdfIds.length"
          @click="onGenerateUniquePdf"
          >Genera PDF</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          :disabled="isLoading"
          @click="onOpenBrokerStatementModal"
          >Invia selezionati</b-button
        >
      </b-col>
    </b-row>

    <b-collapse id="collapse-1" v-model="showCollapse1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="cod_prod"
                label="Produttore"
                :options="salesmen"
                v-model="salesman_id"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="from"
                label="Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="to"
                label="Registrazione Al"
                v-model="filter.byCalendar.to"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="iniziale_from"
                label="Data iniziale rendiconto dal"
                v-model="filter.byFrom.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="iniziale_to"
                label="Data iniziale rendiconto al"
                v-model="filter.byFrom.to"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="finale_from"
                label="Data finale rendiconto dal"
                v-model="filter.byTo.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="finale_to"
                label="Data finale rendiconto al"
                v-model="filter.byTo.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="collected"
                label="Incassato"
                v-model="filter.byAttribute.is_collected"
                :options="[
                  { value: 'Y', text: 'Si' },
                  { value: 'N', text: 'No' },
                ]"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="id"
                label="Id Rendiconto"
                v-model="filter.byAttribute.id"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-show="!isLoading">
        <broker-statements-accounting
          :filterOn="{
            byRelations: ['byBroker', 'byDocument'],
          }"
          @destroy="removeBrokerStatement"
          @report="downloadReport"
          :fields="flds"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :ref="tableRef"
          :onlyActions="['infomodal', 'destroy']"
          noPagination
          @selezionato="onUpdateSelected"
          @spunta="onSelectAll"
          @toglispunta="onUnselectAll"
          @spuntaunique="onUniqueSelectAll"
          @toglispuntaunique="onUniqueDeselectAll"
          @unique="onUnique"
        ></broker-statements-accounting>
      </div>
      <broker-statement-modal
        :ref="modalRef"
        v-model="lscmnc"
        :options="email_templates"
        title="Stai per inviare via e-mail ai produttori tutti i rendiconti selezionati"
        @send="onSend"
      ></broker-statement-modal>
      <!-- <generate-broker-statements-modal
        :ref="generateBrokerStatementModalRef"
        :title="broker_statement_title"
        :header="broker_statement_header"
        @generate="onGenerate"
      ></generate-broker-statements-modal> -->
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BrokerStatementsAccounting from "@/components/tables/BrokerStatementsAccounting";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import ExportsMixin from "@/mixins/ExportsMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import { mapGetters, mapActions } from "vuex";
import BrokerStatementModal from "@/components/modals/sendBrokerStatementModal";
// import GenerateBrokerStatementsModal from "@/components/modals/generateBrokerStatementsModal";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { RepositoryFactory as RepositoryFactoryLC } from "@/repositories/lisacomunica/RepositoryFactory";
import { computed } from "vue";

export default {
  mixins: [CustomFiltersMixin, ConfirmModalMixin, DocumentsMixin, ExportsMixin],
  data() {
    return {
      isLoading: false,
      isSaving: false,
      filter: this.initFilter(),
      salesman_id: null,
      arretrati: null,
      calculus_type: 0, // TODO diventerà byQualcosa...
      showCollapse1: true,
      canShowBrokerStatement: false,
      changedIds: [],
      email_templates: [],
      // broker_statement_title: null,
      // broker_statement_header: null,
      // mode: null,
      uniquePdfIds: [],
      filterName: "filterBrokerStatementsAccounting",
      resource: "broker_statements",
      repository: "broker_statement",
      tableRef: "BrokerStatementsAccountingTableRef",
      modalRef: "sendBrokerStatementModal",
      // generateBrokerStatementModalRef: "generateBrokerStatementModal",
      form: {
        template_id: null, // per Lisacomunica...
      },
      fields: [],
      selectableFields: {
        N: [
          {
            key: "selezionato",
            label: "Sel.",
            sortable: false,
            thStyle: {
              width: "2px",
            },
          },
          {
            key: "id",
            label: "Id",
          },
          {
            key: "broker",
            label: "Produttore",
            sortable: true,
            sortKey: "byBroker.BUID",
          },
          {
            key: "created_date",
            label: "Data creazione",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          {
            key: "from",
            label: "Data inizio",
            sortable: true,
            formatter: (value) => toLocaleDate(value),
            sortKey: "from",
          },
          {
            key: "to",
            label: "Data fine",
            sortable: true,
            formatter: (value) => toLocaleDate(value),
            sortKey: "to",
          },
          {
            key: "premium",
            label: "Totale da versare",
            sortable: true,
            formatter: (value) => toLocaleCurrency(parseFloat(value)),
            thClass: "text-right",
            thStyle: {
              "text-align": "right",
            },
            tdClass: "text-right",
          },
          {
            key: "totalizers",
            label: "Totalizzatori",
            thStyle: {
              "min-width": "200px",
            },
          },
          {
            key: "files",
            label: "File generati",
            thStyle: {
              "min-width": "200px",
            },
          },
          {
            // campo fittizio, solo lato client
            key: "uniquePdfRow",
            label: "Sel.Per.PDF",
            sortable: false,
            thClass: "check",
            tdClass: "text-center",
          },
        ],
        Y: [
          {
            key: "selezionato",
            label: "Sel.",
            sortable: false,
            thStyle: {
              width: "2px",
            },
          },
          {
            key: "rowSelector",
            label: "Dettagli",
            sortable: false,
            thStyle: {
              width: "2px",
            },
          },
          {
            key: "id",
            label: "Id",
          },
          {
            key: "broker",
            label: "Produttore",
            sortable: true,
            sortKey: "byBroker.BUID",
          },
          {
            key: "created_date",
            label: "Data creazione",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          {
            key: "from",
            label: "Data inizio",
            sortable: true,
            formatter: (value) => toLocaleDate(value),
            sortKey: "from",
          },
          {
            key: "to",
            label: "Data fine",
            sortable: true,
            formatter: (value) => toLocaleDate(value),
            sortKey: "to",
          },
          {
            key: "is_collected.text",
            label: "Incassato",
            sortable: false,
          },
          {
            key: "premium",
            label: "Totale da versare",
            sortable: true,
            formatter: (value) => toLocaleCurrency(parseFloat(value)),
            thClass: "text-right",
            thStyle: {
              "text-align": "right",
            },
            tdClass: "text-right",
          },
          {
            key: "totalizers",
            label: "Totalizzatori",
            thStyle: {
              "min-width": "200px",
            },
          },
          {
            key: "files",
            label: "File generati",
            thStyle: {
              "min-width": "200px",
            },
          },
          {
            key: "uniquePdfRow",
            label: "Sel.PDF",
            sortable: false,
            thClass: "check",
            tdClass: "text-center",
          },
        ],
      },
      salesmen: [],
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      uniquePdfIdsLength: computed(() => this.uniquePdfIds.length),
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    BrokerStatementsAccounting,
    BrokerStatementModal,
    // GenerateBrokerStatementsModal,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          is_collected: null,
          id: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byFrom: {
          // REVIEW: temporary name must be changed
          from: null,
          to: null,
        },
        byTo: {
          // REVIEW: temporary name must be changed
          from: null,
          to: null,
        },
      };
      return init;
    },
    onOpenBrokerStatementModal() {
      this.$bvModal.show(this.modalRef);
    },
    // onOpenGenerateBrokerStatements(mode) {
    //   switch (mode) {
    //     case "broker_statement_mode":
    //       this.broker_statement_header = "Genera Rendiconti";
    //       this.broker_statement_title =
    //         "Stai per generare i rendiconti dei produttori selezionati";
    //       this.mode = "broker_statement_mode";
    //       break;
    //     case "commission_mode":
    //       this.broker_statement_header = "Effettua Calcoli Provvigionali";
    //       this.broker_statement_title =
    //         "Stai per generare i calcoli provvigionali dei produttori selezionati";
    //       this.mode = "commission_mode";
    //       break;
    //   }
    //   this.$bvModal.show(this.generateBrokerStatementModalRef);
    // },
    // onOpenGenerateBrokerStatements() {
    //   this.$bvModal.show(this.generateBrokerStatementModalRef);
    // },
    // manipolaItems(data) {
    //   data.forEach((item) => {
    //     if (!item.selected) {
    //       item["selected"] = { text: "", value: null };
    //     }
    //     // if (item.users.length) {
    //     if (item.is_selected.value == "Y") {
    //       item.selected.value = "Y";
    //       item.selected.text = "Si";
    //     } else {
    //       item.selected.value = "N";
    //       item.selected.text = "No";
    //     }
    //   });
    //   return data;
    // },
    onSearch(name) {
      // if (
      //   !this.salesman_id ||
      //   !this.filter.byCalendar.from ||
      //   !this.filter.byCalendar.to
      // ) {
      //   let missing = {};
      //   missing.produttore = !this.salesman_id;
      //   missing.creazione_dal = !this.filter.byCalendar.from;
      //   missing.creazione_al = !this.filter.byCalendar.to;
      //   let msg = `Devi selezionare ${Object.keys(missing)
      //     .filter((key) => missing[key])
      //     .join(", ")}`;
      //   this.$showSnackbar({
      //     preset: "success",
      //     text: msg,
      //     showAction: false,
      //   });
      // } else {
      // some handles for the fetch call...
      let args = { items: { handle: null, params: [] }, verb: {} };
      // // 1. items handle (add fake field uniquePdfRow)
      args.items.handle = this.manipolaItems;
      // let previous = this.filter.byCalendar.from;
      // DEBUG
      // this.filter.bySalesman = {};
      // this.filter.bySalesman.id = this.salesman_id;

      // add broker to query string
      this.filter.byBroker = {};
      this.filter.byBroker.id = this.salesman_id;

      let criteria = this.filter;
      // store the filter
      this.saveFilterByName({ name, criteria });

      // fetch data
      this.$refs[this.tableRef].fetch(args).then(() => {
        // // set summary.ritenuta_perc from first item's broker.attributables.WHT
        // if (this.$refs[this.tableRef].items.length) {
        //   // NOTE nel caso Calcola con Lisaweb, item non ha la proprietà "brokers", quindi metto a 0
        //   this.summary.ritenuta_perc = this.$refs[this.tableRef].items[0]
        //     .brokers
        //     ? this.$refs[this.tableRef].items[0].brokers[0].attributables.WHT
        //     : 0;
        // }
        // // setChangedIds
        // if (this.calculus_type == 1) {
        //   // if calculated value id different, then row must be updatable...
        //   this.setChangedIds(this.$refs[this.tableRef].items);
        // }
        // this.filter.byCalendar.from = previous;
        // this.resetPage();
        this.$refs[this.tableRef].loadSelectedCounter();
        // reset Sel.PDF checkbox
        this.$refs[this.tableRef].myToggleUnique = false;
        this.uniquePdfIds = [];
      });
      this.showCollapse1 = false;
      // }
    },
    onClearFilter(name) {
      // this.$nextTick(() => {
      this.resetFilter();
      // reset salesman_id
      this.salesman_id = null;
      // reset arretrati
      this.arretrati = false;
      this.resetPagination();
      // remove from store
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // clear the sortBy flag
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // fetch data
      // // this.$refs[this.tableRef].fetch();
      // NOTE: since salesman_id is mandatory, cannot fetch (with no filter), but must clear items instead!
      // this.onSearch(name);
      // reset changedIds
      this.changedIds = [];
      // reset items
      this.$refs[this.tableRef].items = [];
      // reset checkAll checkbox
      this.$refs[this.tableRef].checkAll = false;
      // reset Sel.PDF checkbox
      this.uniquePdfIds = [];
      this.$refs[this.tableRef].myToggleUnique = false;
      //this.resetPage();
      // });
    },
    removeBrokerStatement(id) {
      console.debug("Deleting broker_statement: ", id);

      this.isSaving = true;
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.isSaving = false;
          // this.$refs[this.tableRef].fetch();
          this.onSearch(this.filterName);
          this.$showSnackbar({
            preset: "success",
            text: "Rendiconto eliminato",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isSaving = false;
        });
    },
    downloadReport(document) {
      this.downloadDocument(document);
    },
    onSend() {
      this.isLoading = true;
      let RepoLC = RepositoryFactoryLC.get("email");
      RepoLC.start(this.form)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: "E-Mail inviate",
          });
        })
        .catch((error) => {
          let errMsg = `Error ${error.code}: ${error.message}`; // this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.modalRef);
          this.isLoading = false;
        });
    },
    // onGenerate(form) {
    //   this.$bvModal.hide(this.generateBrokerStatementModalRef);

    //   const Repo = RepositoryFactory.get("book_entry");

    //   if (this.mode === "commission_mode") {
    //     form["no_statement"] = "Y";
    //   } else {
    //     form["no_statement"] = "N";
    //   }

    //   Repo.generate_broker_statements(form)
    //     .then(() => {
    //       this.$showSnackbar({
    //         preset: "success",
    //         text: "Processo di generazione dei rendiconti avviato",
    //       });
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //     });
    // },
    onGenerateUniquePdf() {
      if (!this.uniquePdfIds.length) {
        this.$showSnackbar({
          preset: "info",
          text: "Nessun rendiconto selezionato",
        });
        return;
      }
      let code = "AGRENPROV";
      this.onExport("pdf", code)
        .then(() => {})
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
    },
    onSelectAll() {
      this.filterUpdate("spunta");
    },
    onUnselectAll() {
      this.filterUpdate("toglispunta");
    },
    filterUpdate(mode) {
      let filter;
      let qs = {};
      let payload = {
        is_selected: null,
      };
      let tableRef = this.$refs[this.tableRef];
      switch (mode) {
        case "spunta":
          payload.is_selected = "Y";
          filter = tableRef.loadFilter(tableRef.filterName);
          if (filter) {
            qs.filter = filter;
          }
          break;
        case "toglispunta":
          payload.is_selected = "N";
          // qs.filterOn = tableRef.prepareFilterQueryString({
          //   byAttribute: { sent: "N" },
          // });
          break;

        default:
          break;
      }
      let Repo = RepositoryFactory.get(this.repository);
      tableRef.isLoading = true;
      let queryString = Object.values(qs).join("&");
      Repo.filter_update(payload, queryString)
        .then(() => {
          // do not fetch, just loadSelectedCounter()
          tableRef
            .loadSelectedCounter()
            .then(() => {
              // then set all checkboxes of all items in this page
              tableRef.items.forEach((item) => {
                item.is_selected.value = payload.is_selected;
                item.is_selected.text =
                  payload.is_selected == "Y" ? "Si" : "No";
              });
              tableRef.isLoading = false;
              this.$showSnackbar({
                preset: "success",
                text: `Spunta ${
                  mode == "spunta" ? "aggiunta" : "tolta"
                } con successo`,
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdateSelected(row) {
      this.updateSelected(row);
    },
    updateSelected(row) {
      let queryString = `byAttribute[id]=${row.id}`;
      let payload = {
        is_selected: row.is_selected.value,
      };
      let tableRef = this.$refs[this.tableRef];
      let Repo = RepositoryFactory.get(this.repository);
      tableRef.isLoading = true;
      Repo.filter_update(payload, queryString)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Aggiornato con successo`,
          });
          // do not fetch, just loadSelectedCounter()
          tableRef
            .loadSelectedCounter()
            .then(() => {
              tableRef.isLoading = false;
            })
            .catch((error) => {
              tableRef.isLoading = false;
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    addToUniquePdfIds(id) {
      // add to global list
      if (this.uniquePdfIds.indexOf(id) === -1) {
        this.uniquePdfIds.push(id);
      }
    },
    removeFromUniquePdfIds(id) {
      let index = this.uniquePdfIds.indexOf(id);
      if (index !== -1) {
        this.uniquePdfIds.splice(index, 1);
      }
    },
    manipolaItems(data) {
      // add fake field "uniquePdfRow"
      data.forEach((item) => {
        this.$set(item, "uniquePdfRow", false);
      });
      return data;
    },
    onUnique(item) {
      if (item.uniquePdfRow) {
        // checked..
        this.addToUniquePdfIds(item.id);
      } else {
        // unchecked
        this.removeFromUniquePdfIds(item.id);
      }
    },
    onUniqueSelectAll() {
      let extendedData = this.$refs[this.tableRef].items.map((obj) => ({
        ...obj,
        uniquePdfRow: true,
      }));
      this.$refs[this.tableRef].items = extendedData;
      // add all ids
      this.uniquePdfIds = this.$refs[this.tableRef].items.map((e) => e.id);
    },
    onUniqueDeselectAll() {
      let extendedData = this.$refs[this.tableRef].items.map((obj) => ({
        ...obj,
        uniquePdfRow: false,
      }));
      this.$refs[this.tableRef].items = extendedData;
      // reset all ids
      this.uniquePdfIds = [];
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {
      removePaginationByName: "removeByName",
      savePaginationByName: "saveByName",
    }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  beforeMount() {
    this.isLoading = true;
    this.salesmen = this.getSalesmen();
    // do not AUTOMATIC FETCH
    this.removeFilterByName(this.filterName);
    // // also load counter
    // this.$refs[this.tableRef].loadSelectedCounter();
    // carico i template lisacomunica email
    const RepoLC = RepositoryFactoryLC.get("email");
    // filtrare per tipo 'Rendiconto'
    RepoLC.index("type=REN_PRO")
      .then((response) => {
        console.log("email", response.data.content);
        this.email_templates = response.data.content.map((e) => {
          return { value: e.id, text: e.title };
        });
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: errMsg,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  mounted() {
    // also load counter
    this.$refs[this.tableRef].loadSelectedCounter();
  },
  computed: {
    flds() {
      return this.selectableFields[
        this.$store.state.auth.settings.attribute_STATMANB_value
      ];
    },
    lscmnc() {
      return this.form;
    },
  },
};
</script>
