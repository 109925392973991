<template>
  <b-modal
    id="copyCommissions"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="">
      <div slot="modal-title" class="modal-title">
        <!-- <b-icon icon="cash"></b-icon> -->
        <span>{{ title }}</span>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-button
          @click="
            reset();
            cancel();
          "
          size="sm"
          variant="outline-secondary"
          >ANNULLA</b-button
        >
        <b-button
          class="btn-outline-lisaweb"
          :disabled="!options.length"
          @click="
            $emit('copy', options);
            reset();
            cancel();
          "
          size="sm"
          variant="outline-success"
          >COPIA</b-button
        >
      </b-form-group>
    </template>
    <b-row>
      <!-- testo libero -->
      <p class="col-md-12 mb-3" v-html="text"></p>
    </b-row>
    <b-row>
      <div class="col-md-8">
        <base-select
          v-model="options"
          name="options"
          label=""
          :options="opts"
          :multiple="true"
          :taggable="true"
          :closeOnSelect="false"
          @select="onOption"
          @remove="onOption"
        />
      </div>
      <div class="form-group col-md-4 align-self-end">
        <b-button :pressed.sync="myToggle" variant="light" @click="onToggle">{{
          myToggle ? "Deseleziona tutti" : "Seleziona tutti"
        }}</b-button>
      </div>
    </b-row>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
export default {
  data() {
    return {
      options: [],
      myToggle: false,
    };
  },
  components: {
    BaseSelect,
  },
  props: {
    opts: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "Opzioni",
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {},
  beforeMount() {},
  methods: {
    onOption() {
      if (this.options.length) {
        this.myToggle = this.options.length === this.opts.length;
      } else {
        this.myToggle = false;
      }
    },
    onToggle() {
      if (this.myToggle) {
        this.options = this.opts.map((e) => e.value);
      } else {
        this.options = [];
      }
    },
    reset() {
      this.options = [];
      this.myToggle = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
