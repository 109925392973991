/* 
per creare una commission
POST /api/commissions
{
    "purchase_calculation_type": 0,
    "purchase_commission": 10,
    "take_calculation_type": 0,
    "take_commission": 10,
    "salesman | cooperator": {
        "1": {}
    },
    "insurer + risk_branch | insurance_risk | breakdown": {
        "1": {}
    }
}

per modificare una commisison già esistente:
PUT /api/commissions/:commission
{
    "purchase_calculation_type": 0,
    "purchase_commission": 10,
    "take_calculation_type": 0,
    "take_commission": 10,
    "salesman | cooperator": {
        "1": {}
    },
    "insurer + risk_branch | insurance_risk | breakdown": {
        "1": {}
    }
}
*/

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import { showSnackbar } from "@/utils/messages";

export default {
  data() {
    return {
      isLoading: false,
      beForm: {},
      beRules: {},
      ok: false, // status flag if error then false
      message: "", // error message to display
      version: "",
      visibleStates: [false, false, false],
    };
  },
  methods: {
    fetch(level) {
      const Repo = RepositoryFactory.get(this.repository);
      if (!Repo) {
        let errorMsg = `Unknown Repo: ${this.repository}`;
        console.error(errorMsg);
        this.$showSnackbar({
          preset: "error",
          showAction: false,
          text: errorMsg,
        });
        return;
      }
      this.isLoading = true;

      return Repo.specifications(
        this.salesman_id,
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byInsuranceRisk.id
      )
        .then((response) => {
          let data = response.data.data;
          /* [11/10 2:04 PM] Seu Sergio
          in teoria dovresti vedere quando ti arrivano i data, 
          se hanno dentro più righe in commision, che siano di quel broker, 
          potrebbe essercene una o zero di quel broker su potenzialmente 100 in quell'array

          [11/10 2:04 PM] Seu Sergio
          però rimane il fatto che non si possano avere i dati filtrati 
          per il filtro che ci indica non ha senso eh

          [11/10 2:05 PM] Seu Sergio
          ed in teoria non usi più il bySpec e lasci il nuovo bySpecIns

          [11/10 2:06 PM] Seu Sergio
          in ogni data c'è commission, dentro ci possono essere 100 righe, una per broker, 
          se una è del broker aperto a monte, quella è la sua commissione, 
          altrimenti non c'è e si mette come se l'array fosse vuoto
          */

          // filtra le commission
          data.forEach((element) => {
            element.commissions = element.commissions.filter(
              (e) => e.broker_id == this.salesman_id
            );
          });

          // split already existing (for update) and new (for insert)
          let pdata = [];
          let npdata = [];

          // prepare the form for pdata and npdata
          this.$delete(this.form[`level${level}`], "pdata");
          this.$set(this.form[`level${level}`], "pdata", []);

          this.$delete(this.form[`level${level}`], "npdata");
          this.$set(this.form[`level${level}`], "npdata", []);

          let tmpPD = [];
          let tmpNPD = [];
          switch (level) {
            case 1:
              for (const o of data) {
                if (o.commissions.length) {
                  pdata.push(o);
                } else {
                  npdata.push(o);
                }
              }
              for (let d of pdata) {
                tmpPD.push({
                  id: d.id,
                  title: d.title,
                  code: d.code,
                  commission_id: d.commissions[0].id,
                  purchase_calculation_type:
                    d.commissions[0].purchase_calculation_type.value,
                  purchase_commission: d.commissions[0].purchase_commission,
                  take_calculation_type:
                    d.commissions[0].take_calculation_type.value,
                  take_commission: d.commissions[0].take_commission,
                });
              }
              this.form[`level${level}`].pdata = tmpPD;
              for (let d of npdata) {
                tmpNPD.push({
                  id: d.id,
                  title: d.title,
                  code: d.code,
                  purchase_calculation_type: 0,
                  purchase_commission: 0,
                  take_calculation_type: 0,
                  take_commission: 0,
                });
              }
              this.form[`level${level}`].npdata = tmpNPD;
              break;
            case 2:
              for (const o of data) {
                if (o.commissions.length) {
                  pdata.push(o);
                } else {
                  npdata.push(o);
                }
              }
              for (let d of pdata) {
                tmpPD.push({
                  id: d.id,
                  title: d.title,
                  code: d.code,
                  commission_id: d.commissions[0].id,
                  purchase_calculation_type:
                    d.commissions[0].purchase_calculation_type.value,
                  purchase_commission: d.commissions[0].purchase_commission,
                  take_calculation_type:
                    d.commissions[0].take_calculation_type.value,
                  take_commission: d.commissions[0].take_commission,
                });
              }
              this.form[`level${level}`].pdata = tmpPD;
              for (let d of npdata) {
                tmpNPD.push({
                  id: d.id,
                  title: d.title,
                  code: d.code,
                  purchase_calculation_type: 0,
                  purchase_commission: 0,
                  take_calculation_type: 0,
                  take_commission: 0,
                });
              }
              this.form[`level${level}`].npdata = tmpNPD;
              break;
            case 3:
              for (const o of data) {
                if (o.commissions.length) {
                  pdata.push(o);
                } else {
                  npdata.push(o);
                }
              }
              for (let d of pdata) {
                tmpPD.push({
                  id: d.id,
                  title: d.title,
                  // code: d.code,
                  commission_id: d.commissions[0].id,
                  purchase_calculation_type:
                    d.commissions[0].purchase_calculation_type.value,
                  purchase_commission: d.commissions[0].purchase_commission,
                  take_calculation_type:
                    d.commissions[0].take_calculation_type.value,
                  take_commission: d.commissions[0].take_commission,
                });
              }
              // this.$set(this.form[`level${level}`].pdata, ...tmpPD);
              this.form[`level${level}`].pdata = tmpPD;
              for (let d of npdata) {
                tmpNPD.push({
                  id: d.id,
                  title: d.title,
                  // code: d.code,
                  purchase_calculation_type: 0,
                  purchase_commission: 0,
                  take_calculation_type: 0,
                  take_commission: 0,
                });
                // this.buttons["npdata"][i] = {};
                // this.buttons["npdata"][i].enabled = false;
              }
              // this.$set(this.form[`level${level}`].npdata, ...tmpNPD);
              this.form[`level${level}`].npdata = tmpNPD;
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCommission(id, payload) {
      return this.update(this.repository, id, payload);
    },
    storeCommission(payload) {
      return this.store(this.repository, payload);
    },
    cloneCommission(brokerId, insurerId, payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.clone(brokerId, insurerId, payload);
    },
    deleteCommission(commission_id, level, repository, type) {
      this.isLoading = true;
      this.$set(this.buttons[`level${level}`], type, {});
      const Repo = RepositoryFactory.get(repository);
      Repo.destroy(commission_id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Capitolato eliminato`,
          });
          // reload
          this.loadData(
            level,
            this.filter.byRiskBranch.id,
            this.filter.byInsuranceRisk.id
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * 
      - livello 1(compagnia e codice ramo) => selezione prodotto => mostra livello 2, nasconde livello 1
      - livello 2(prodotto) => selezione scorporo => mostra livello 3, nasconde livello 2
      - livello 2(prodotto) => chiusura => nasconde livello 2, mostra livello 1
      - livello 3(scorporo) => chiusura => nasconde livello 3, mostra livello 2
     */
    expand(level) {
      let index = level - 1;
      this.visibleStates = this.visibleStates.map((val, ndx) =>
        ndx === index ? true : false
      );
    },
    collapse(level) {
      let index = level - 1;
      this.visibleStates = this.visibleStates.map((val, ndx) =>
        ndx === index || ndx !== index - 1 ? false : true
      );
    },
  },
};
