<template>
  <div class="mt-1 mx-3">
    <salesmen> </salesmen>
  </div>
</template>

<script>
import Salesmen from "@/components/profile/brokers/salesmen/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Salesmen,
  },
  created() {
    this.notifyQueue({ text: "Produttori", path: "brokers.index0", level: 1 });
  },
};
</script>
