<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="envelope" aria-hidden="true"></b-icon>
        <span>Invia selezionate</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>
    <p>{{ title }}</p>

    <!-- <template #modal-footer="{ cancel }">
      <b-button @click="cancel()" variant="outline-secondary" size="sm"
        >CHIUDI</b-button
      >
    </template> -->

    <div class="mt-2">
      <base-select
        vid="template"
        label="Seleziona un modello e-mail"
        name="Template"
        v-model="inputVal.template_id"
        :options="options"
      />
    </div>

    <b-button
      @click="onSend"
      type="button"
      :disabled="!inputVal.template_id"
      variant="outline-lisaweb"
      size="sm"
      class="mt-2 ml-4 float-right"
    >
      Invia
    </b-button>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
// import { RepositoryFactory as RepositoryFactoryLC } from "@/repositories/lisacomunica/RepositoryFactory";
// import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  data() {
    return {
      // email_templates: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "sendBrokerStatementModal",
    },
    value: null,
    title: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    BaseSelect,
  },
  methods: {
    onSend() {
      // const payload = {
      //   template_id: this.inputVal.template_id,
      // };
      this.$emit("send");
    },
    close() {},
    // ...mapGetters("auth", {
    //   getInsurers: "insurers",
    // }),
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    /* // email
    let Repo = RepositoryFactoryLC.get("email");
    // filtrare per tipo 'Rendiconto'
    Repo.index("type=REN_PRO")
      .then((response) => {
        console.log("email", response.data.content);
        this.email_templates = response.data.content;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: errMsg,
        });
      });
    // .finally(() => {
    //   this.isLoading = false;
    // }); */
  },
};
</script>
<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: auto; unset or auto value is not ok */
  overflow-y: visible;
}
</style>
