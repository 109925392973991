<template>
  <div class="mt-1 mx-3">
    <salesmen-create> </salesmen-create>
  </div>
</template>

<script>
import SalesmenCreate from "@/components/profile/brokers/salesmen/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    SalesmenCreate,
  },
  created() {
    this.notifyQueue({
      text: "Produttori",
      path: "module.PROF.PRCO",
      tab: "#Salesmen",
      level: 3,
    });
    this.notifyQueue({
      text: "Crea Produttore",
      path: "brokers.create0",
      level: 4,
    });
  },
};
</script>
