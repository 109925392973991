<template>
  <!-- <div v-if="!isLoading"> -->
  <div class="mt-2">
    <div>
      <b-table
        :busy="isLoading"
        head-variant="light"
        :no-local-sorting="true"
        :items="items"
        :fields="getAllFields"
        :current-page="currentPage"
        per-page="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :sticky-header="height"
        no-border-collapse
        responsive
        show-empty
        empty-text="Non ci sono informazioni da mostrare"
        small
        @sort-changed="sortingChanged"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <base-icon name="loading" width="35" height="35" />
            <p>Operazione in corso...</p>
          </div>
        </template>

        <template #cell(broker)="{ item }" v-if="items.length">
          <span v-html="toInfoData(item.broker, 'broker', 0)"></span>
        </template>

        <template #cell(rowSelector)="row">
          <div class="action-buttons">
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onChosen(row.item)"
              class="mt-1"
              title="Apri Modale Custom"
              ><b-icon icon="eye"></b-icon>
            </b-button>
          </div>
        </template>

        <template #cell(totalizers)="{ item }" v-if="items.length">
          <!-- <span v-html="toTotalizers(item.totalizers)"></span> -->
          <!-- new fashion -->
          <span v-html="toTotalizers(item)"></span>
        </template>

        <!-- <template #cell(files)="{ item }" v-if="items.length">
          <div v-for="(document, index) in item.documents" :key="index">
            <b-link href="download" @click.prevent="downloadReport(document)">
              <span v-html="document.title"></span>
            </b-link>
          </div>
        </template> -->
        <template #cell(files)="{ item }" v-if="items.length">
          <ul>
            <li v-for="(document, index) in item.documents" :key="index">
              <b-link href="download" @click.prevent="downloadReport(document)">
                <span v-html="document.title"></span>
              </b-link>
            </li>
          </ul>
        </template>

        <!-- use @change not @input in order to trigger only user interaction -->
        <template #cell(selezionato)="{ item }">
          <base-checkbox
            name="selected"
            class="check"
            v-model="item.is_selected.value"
            chk_val="Y"
            unchk_val="N"
            @change="onSelezionato($event, item)"
          />
        </template>

        <template #cell(actions)="row">
          <div class="action-buttons">
            <b-button
              v-if="actions.includes('infomodal') && is_STATMANB()"
              size="sm"
              variant="lisaweb"
              @click="openModal(row.item, row.index, $event.target)"
              class="mt-1 mr-1"
              title="Modale Dettagli"
            >
              <b-icon icon="eye"></b-icon>
            </b-button>

            <b-button
              v-if="actions.includes('details')"
              size="sm"
              variant="lisaweb"
              @click="row.toggleDetails"
              class="mt-1 mr-1"
              :title="row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'"
            >
              <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
              <b-icon v-else icon="chevron-down"></b-icon>
            </b-button>

            <b-button
              v-if="actions.includes('edit')"
              size="sm"
              variant="lisaweb"
              @click="onEdit(row.item.id)"
              class="mt-1 mr-1"
              title="Modifica"
            >
              <b-icon icon="pencil-square"></b-icon>
            </b-button>

            <b-button
              v-if="actions.includes('destroy')"
              size="sm"
              variant="lisaweb"
              @click="onDestroy(row.item.id)"
              class="mt-1 mr-1"
              title="Elimina"
              :disabled="isCollected(row.item)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </template>

        <template #cell(uniquePdfRow)="{ item }">
          <base-checkbox
            name="uniquePdfRow"
            class="check"
            v-model="item.uniquePdfRow"
            :chk_val="true"
            :unchk_val="false"
            @change="onChosen(item, 'unique')"
          />
          <!-- :disabled="isTableReadonly || !isChangeable(item)"" -->
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2" v-for="(value, key) in row.item" :key="key">
              <b-col sm="3" class="text-sm-right"
                ><b>{{ key | capitalize }}:</b></b-col
              >
              <b-col>{{ value }}</b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>

    <b-row v-if="hasChecksButtonGroup">
      <b-col align="left">
        <b-button-group size="md" class="">
          <b-button
            variant="transparent"
            class="text-decoration-none"
            @click="spuntaTutte()"
          >
            <b-icon icon="check" class="check-icon"></b-icon>Spunta tutte
          </b-button>
          <b-button
            variant="transparent"
            class="text-decoration-none"
            @click="togliSpuntaTutte()"
          >
            <b-icon icon="x" class="cross-icon"></b-icon>Togli spunte a tutte
          </b-button>
          <p class="select-all">Selezionate: {{ cntr }}</p>
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button-group size="md" class="">
          <p class="select-all">Selezionate: {{ cntrunq }}</p>
          <b-button
            :pressed.sync="myToggleUnique"
            variant="light"
            class="mb-2 ml-2"
            @click="
              myToggleUnique ? spuntaUniqueTutti() : togliSpuntaUniqueTutti()
            "
            >{{
              myToggleUnique ? "Deseleziona tutti" : "Seleziona tutti"
            }}</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>

    <b-row v-if="!noPagination">
      <div class="show-text">
        <p>Mostra</p>
      </div>
      <div class="group">
        <b-form-group
          label-for="per-page-select"
          label-cols-sm="3"
          label-cols-md=""
          label-cols-lg="3"
          label-size="sm"
          md="auto"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            style="width: 68px"
            size="sm"
            @change="onPageChange(1)"
            :disabled="!items.length"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="page">
        <p>di {{ totalItems }} per pagina</p>
      </div>
      <div class="pagination" v-if="totalItems">
        <b-pagination
          v-model="currentPage"
          limit="3"
          :total-rows="totalItems"
          :per-page="perPage"
          size="sm"
          @change="onPageChange"
        ></b-pagination>
      </div>
    </b-row>

    <b-modal
      :id="infomodalName"
      @hidden="onModalHidden"
      :title="modalTitle"
      size="xl"
      ok-only
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      no-close-on-backdrop
    >
      <div>
        <b-tabs content-class="pt-1" lazy v-model="tabIndex">
          <b-tab
            v-for="(tab, index) in tabs"
            :active="index === tabIndex"
            :key="index"
            :title="tab.text"
            @click="setTab(tab, index)"
            title-item-class="lisaweb"
          >
            <button-group-tab> </button-group-tab>
            <keep-alive>
              <component
                v-if="selected === tab.name"
                :is="selected"
                :resourceId="modalResourceId"
                @fetch="fetch"
                @close="closeModal"
              ></component>
            </keep-alive>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import template from "./template";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/accounting/accounting-prod/broker-statements/details/General";
// import { toLocaleCurrency } from "@/utils/strings";
import { toTotalizers } from "@/utils/transforms";
import moment from "moment";
moment.locale("it");

export default {
  inject: ["uniquePdfIdsLength"],
  name: "BrokerStatementsAccountingTable",
  extends: template,
  data() {
    return {
      counterSelezionate: 0,
      myToggleUnique: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [{ name: "General", text: "Generale" }],
      selected: "General", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
    };
  },
  components: {
    BaseIcon,
    General,
    ButtonGroupTab,
    BaseCheckbox,
  },
  computed: {
    isChecked() {
      return (item) => {
        return item.selezionato;
      };
    },
    cntr() {
      return this.counterSelezionate;
    },
    cntrunq() {
      // return this.$parent.$parent.uniquePdfIds.length;
      return this.uniquePdfIdsLength;
    },
  },
  mounted() {},
  props: {
    hasChecksButtonGroup: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    // toLocaleCurrency,
    toTotalizers,
    // TODO items: se VariousAccounting id è 3, allora metto il segno - al gross
    // TODO: override della fecth

    // override the default function defined in template
    // onSelectRow(value, item) {
    //   console.debug(`checked: ${value}, id: ${item.id}`);
    //   // TODO call backend and save the checked status of this item

    //   // then()... fetch data
    //   // this.fetch()
    // },
    loadSelectedCounter() {
      // const userId = this.$store.state.auth.user.id;
      // let queryString = `byUser[id]=${userId}&byAttribute[sent]=N&count`;
      let queryString = "byAttribute[is_selected]=Y&count";
      let Repo = RepositoryFactory.get("broker_statement");
      return Repo.index(queryString)
        .then((response) => {
          this.counterSelezionate = response.data.data.count;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSelezionato(value, item) {
      console.debug("onSelezionato");
      console.debug(value, item);
      this.$emit("selezionato", item);
    },
    spuntaTutte() {
      this.$emit("spunta");
    },
    togliSpuntaTutte() {
      this.$emit("toglispunta");
    },
    spuntaUniqueTutti() {
      this.$emit("spuntaunique");
    },
    togliSpuntaUniqueTutti() {
      this.$emit("toglispuntaunique");
    },
    is_STATMANB() {
      return this.$store.state.auth.settings.attribute_STATMANB_value === "Y";
    },
    isCollected(item) {
      return item?.is_collected?.value === "Y" ? true : false;
    },
    downloadReport(document) {
      this.$emit("report", document);
    },
    info(item, index, button) {
      // TODO: check if it's possible perform async calls here!!!
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$bvModal.show(this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    // toTotalizers(array, sep = "<br/>") {
    //   let t = [];
    //   if (Array.isArray(array)) {
    //     array.forEach((e) => {
    //       for (const [k, v] of Object.entries(e)) {
    //         t.push(`${k}: ${this.toLocaleCurrency(v)}`);
    //       }
    //     });
    //   }
    //   return t.join(sep);
    // },
  },
};
</script>
<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
.select-all {
  font-size: 12px;
  margin-top: 7px;
  margin-left: 8px;
}
.check-icon {
  color: green;
}
.cross-icon {
  color: red;
}
.text-decoration-none {
  font-size: 12px;
}
.totalRow {
  text-align: right;
}
/* nelle table con form input, toglie il margine */
:deep(table .form-group) {
  margin-bottom: 0rem !important;
}
/* VIOLA */
:deep(.effective-status-0 td:nth-child(3)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 0, 255, 0.3) 100%
  );
}
/* ARANCIONE */
:deep(.effective-status-1 td:nth-child(3)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(253, 173, 0, 0.4) 100%
  );
}
/* ROSA */
:deep(.effective-status-2 td:nth-child(3)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 0, 0, 0.3) 100%
  );
}
/* NEUTRO */
:deep(.effective-status-3 td:nth-child(3)) {
  background: rgb(255, 255, 255);
}

:deep(.coin) {
  box-shadow: 0px 0px 8px 2px #f9991e;
  border: 1px solid #ffc107;
}
</style>
